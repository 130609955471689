// import React from "react";
// import BreadCrumb from "../../../Breadcumb/Breadcumb";
// import ServiceBannerImg from "../../../Images/contact-back.jpg";
// import titleimage from "../../../Images/title-icon.png";
// import Influencer from "../../../Images/Influencer-bg.png";
// import SeoImg from "../../../Images/seo.png";
// import ProfileImg from "../../../Images/profile.png";
// import RetinaImg from "../../../Images/retina.png";
// import MegaPhoneImg from "../../../Images/megaphone.png";
// import HomeBackImg from "../../../Images/home-page-back.png";
// import FeatureImg1 from "../../../Images/feature-1.jpg";
// import FeatureImg2 from "../../../Images/feature-2.png";
// import FeatureImg3 from "../../../Images/feature-3.jpg";
// import Slider from "react-slick";
// import OurBrandImg1 from "../../../Images/our_brand_logos_new-1.jpg";
// import OurBrandImg2 from "../../../Images/our_brand_logos_new-2.jpg";
// import OurBrandImg3 from "../../../Images/our_brand_logos_new-3.jpg";
// import OurBrandImg4 from "../../../Images/our_brand_logos_new-4.jpg";
// import CapaignsImg1 from "../../../Images/campaign-1.png";
// import CapaignsImg2 from "../../../Images/campaign-2.png";
// import CapaignsImg3 from "../../../Images/campaign-3.png";
// import CapaignsImg4 from "../../../Images/campaign-4.png";
// import CapaignsImg5 from "../../../Images/campaign-5.png";
// import CapaignsImg6 from "../../../Images/campaign-6.png";
// import usePageSEO from "../../usepageSEO/Index";

// const HowItWorks = () => {
//   usePageSEO(
//     "Viral kar | How It Work", // Use page title for SEO if available
//     "Viral kar | How It Work", // Use page description for SEO if available
//     ["Viral kar | How It Work"] // No keywords provided in this example
//   );
//   const brands = {
//     dots: false,
//     infinite: true,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     vertical: true,
//     verticalSwiping: true,
//     autoplay: true,
//     speed: 2000,
//     autoplaySpeed: 2000,
//     cssEase: "linear",
//     beforeChange: function (currentSlide, nextSlide) {
//       console.log("before change", currentSlide, nextSlide);
//     },
//     afterChange: function (currentSlide) {
//       console.log("after change", currentSlide);
//     },
//   };

//   const capaigns = {
//     dots: false,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 3,
//     initialSlide: 0,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 3,
//           infinite: true,
//           dots: false,
//         },
//       },
//       {
//         breakpoint: 786,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//           infinite: true,
//           dots: false,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//           initialSlide: 2,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   const breadcrumbItems = [
//     {
//       text: "How it works",
//     },
//   ];
//   const backgroundImage = ServiceBannerImg;

//   const featuredata = [
//     { image: FeatureImg1 },
//     { image: FeatureImg2 },
//     { image: FeatureImg3 },
//   ];

//   const brandsdata = [
//     { image: OurBrandImg1 },
//     { image: OurBrandImg2 },
//     { image: OurBrandImg3 },
//     { image: OurBrandImg4 },
//   ];

//   const capaignsdata = [
//     { image: CapaignsImg1 },
//     { image: CapaignsImg2 },
//     { image: CapaignsImg3 },
//     { image: CapaignsImg4 },
//     { image: CapaignsImg5 },
//     { image: CapaignsImg6 },
//   ];
//   return (
//     <div>
//       <BreadCrumb items={breadcrumbItems} backgroundImage={backgroundImage} />
//       <div className="container mx-auto py-7 px-4 lg:px-0">
//         <h1 className="page-title text-5xl text-center pb-4">Why Us?</h1>
//         <img src={titleimage} alt="" className="mx-auto title-icon" />

//         <div className="grid grid-cols-1 md:grid-cols-1 xl:grid-cols-2 py-10 lg:gap-16 gap-10">
//           <div className="">
//             <div className="relative overflow-hidden xl:h-[60rem] lg:h-[68.5rem] md:h-96">
//               <img
//                 src={Influencer}
//                 alt=""
//                 className="h-full w-full lg:object-cover object-contain lg:object-center"
//               />
//             </div>
//           </div>
//           <div className="my-auto lg:px-28 xl:px-0 px-0">
//             <div className="md:flex md:space-x-6">
//               <img
//                 src={SeoImg}
//                 alt=""
//                 className="lg:w-24 w-16 lg:h-24 h-16 my-auto mx-auto"
//               />
//               <div className="my-auto shadow-[1px_1px_10px_1px_rgba(0,0,0,0.3)] mt-3 lg:mt-0 bg-white p-5">
//                 <h1 className="lg:text-2xl font-medium ">
//                   Join the <span className="text-[plum]">#1 destination </span>
//                   where brands search Influencers for brand campaigns in India
//                 </h1>
//                 <p className="lg:text-lg text-sm text-zinc-500 mt-2">
//                   Be a part of the community where 6700+ brands search for
//                   Influencers every month for campaigns/events.
//                 </p>
//               </div>
//             </div>
//             <div className="md:flex md:space-x-6 mt-5">
//               <img
//                 src={ProfileImg}
//                 alt=""
//                 className="lg:w-24 w-16 lg:h-24 h-16 my-auto mx-auto lg:mx-0"
//               />
//               <div className="my-auto shadow-[1px_1px_10px_1px_rgba(0,0,0,0.3)] mt-3 lg:mt-0 bg-white p-5">
//                 <h1 className="lg:text-2xl font-medium ">
//                   Create a <span className="text-[plum]">Free Profile </span>
//                   in a hassle free manner
//                 </h1>
//                 <p className="lg:text-lg text-sm text-zinc-500 mt-2">
//                   By creating your profile on Qoruz the chances of being visible
//                   to brands is 3x compared to any other platforms..
//                 </p>
//               </div>
//             </div>
//             <div className="md:flex md:space-x-6 mt-5">
//               <img
//                 src={RetinaImg}
//                 alt=""
//                 className="lg:w-24 w-16 lg:h-24 h-16 my-auto mx-auto lg:mx-0"
//               />
//               <div className="my-auto shadow-[1px_1px_10px_1px_rgba(0,0,0,0.3)] mt-3 lg:mt-0 bg-white p-5">
//                 <h1 className="lg:text-2xl font-medium ">
//                   Be visible to{" "}
//                   <span className="text-[plum]"> 6700+ Brands</span>
//                   throughout the country
//                 </h1>
//                 <p className="lg:text-lg text-sm text-zinc-500 mt-2">
//                   Be on top of the brand searches by creating your profile and
//                   work on different brand collaborations in your niche.
//                 </p>
//               </div>
//             </div>
//             <div className="md:flex md:space-x-6 mt-5">
//               <img
//                 src={MegaPhoneImg}
//                 alt=""
//                 className="lg:w-24 w-16 lg:h-24 h-16 my-auto mx-auto lg:mx-0"
//               />
//               <div className="my-auto shadow-[1px_1px_10px_1px_rgba(0,0,0,0.3)] mt-3 lg:mt-0 bg-white p-5">
//                 <h1 className="lg:text-2xl font-medium ">
//                   Join the{" "}
//                   <span className="text-[plum]">exclusive access </span>
//                   where brands search Influencers for brand campaigns in India
//                 </h1>
//                 <p className="lg:text-lg text-sm text-zinc-500 mt-2">
//                   Not just paid collaborations, build strong relationships with
//                   the brands and get exclusive access to the brand events and
//                   much more.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div
//         className="subscribe-bg lg:mb-0 relative bg-cover bg-center bg-no-repeat px-4 xl:px-0"
//         style={{ backgroundImage: `url(${HomeBackImg})` }}
//       >
//         <div className="container mx-auto py-10">
//           <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
//             {featuredata.map((val, index) => (
//               <div className="models-box relative" key={index}>
//                 <div className="relative overflow-hidden xl:h-[20rem] lg:h-52 md:h-96 rounded-xl">
//                   <img
//                     src={val.image}
//                     alt=""
//                     className="h-full w-full object-contain lg:object-center"
//                   />
//                 </div>
//                 <div class="overlay py-4">
//                   <div class="block-features-name bg-black py-2">
//                     <span className="text-[plum] text-3xl mb-4">
//                       Paid Brand Collaborations
//                     </span>
//                   </div>
//                   <div class="features-content">
//                     <p className="bg-black px-5 pb-4 text-base rounded-b-xl">
//                       From simple shoutouts to unboxing videos, get paid for the
//                       content you love to create.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>

//       {/*------------- Over 6700 brands are -------------*/}
//       <div className="container mx-auto xl:py-20 py-5 px-4 xl:px-0">
//         <div className="grid grid-cols-1 lg:grid-cols-3">
//           <div className="col-span-2 my-auto">
//             <h1 className="text-5xl  ">
//               Over <span className="text-[plum]">6700 brands</span> are
//             </h1>
//             <h1 className=" text-5xl   pt-3">Searching for Influencers</h1>
//             <p className="pt-2 text-2xl text-zinc-500">
//               Top brands from all over the globe are searching for influencers
//               like you on Qoruz.
//             </p>
//             <button className="bg-gradient-to-r from-[#FF44F4] from-10% to-[#E627F3] to-90% text-white font-medium shadow-xl p-3 px-8 rounded mt-16">
//               Create my Profile
//             </button>
//           </div>
//           <div className="mt-5 xl:mt-0">
//             <div className="innerImg border-r border-l">
//               <Slider {...brands}>
//                 {brandsdata.map((val, index) => (
//                   <div key={index} className=" ">
//                     <div className="relative overflow-hidden xl:h-[24rem] lg:h-[20.5rem] md:h-96 rounded-xl">
//                       <img
//                         src={val.image}
//                         alt=""
//                         className="h-full w-full object-contain "
//                       />
//                     </div>
//                   </div>
//                 ))}
//               </Slider>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container mx-auto xl:py-20 lg:pb-40 py-10 pb-52 md:pb-40 px-7 xl:px-0">
//         <h1 className="lg:text-4xl text-2xl text-center font-bold pb-2">
//           Some of <span className="text-[plum]">The Capaigns</span>{" "}
//         </h1>
//         <h1 className="lg:text-4xl text-2xl text-center pb-3 font-bold">
//           You've Missed..
//         </h1>
//         <img src={titleimage} alt="" className="mx-auto title-icon" />

//         <div className="banner lg:mt-8 mt-5 ">
//           <Slider {...capaigns}>
//             {capaignsdata.map((val, index) => (
//               <div>
//                 <div className="relative overflow-hidden xl:h-[34rem] lg:h-[20.5rem] md:h-96 rounded border border-zinc-500  shadow-[1px_1px_10px_1px_rgba(0,0,0,0.3)]">
//                   <img
//                     src={val.image}
//                     alt=""
//                     className="h-full w-full object-contain "
//                   />
//                 </div>
//               </div>
//             ))}
//           </Slider>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HowItWorks;

import React from "react";
import { FaUserFriends, FaSearch, FaHandshake, FaStar } from "react-icons/fa";
import processImage1 from "../../../Images/profile.png";
import processImage2 from "../../../Images/possibility.png";
import processImage3 from "../../../Images/connect.png";
import processImage4 from "../../../Images/target.png";
import Breadcrumb from "../../../Breadcumb/Breadcumb";
import titleimage from "../../../Images/title-icon.png";
import HowItsBanner from "../../../Images/how-its-work-banner.jpg";
import { Link } from "react-router-dom";

const HowItWorks = () => {
  const breadcrumbItems = [
    {
      text: "How it works",
    },
  ];

  return (
    <div className="dark:bg-darkmode ">
      <Breadcrumb items={breadcrumbItems} backgroundImage={HowItsBanner} />

      <div className="pt-12">
        <div className="max-w-7xl mx-auto text-center">

          <h1 className=" page-title text-3xl text-center dark:text-white pb-3">
            How ViralKar.com Works
          </h1>
          <img src={titleimage} alt="" className="mx-auto title-icon" />

          <p className="text-gray-700 text-lg dark:text-white mt-5 mb-12 md:px-0 px-3">
            Discover our streamlined process designed to help you connect,
            explore opportunities, collaborate, and achieve your goals
            effortlessly.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 px-5 xl:px-0">
            {/* Step 1 */}
            <div className="relative bg-gradient-to-r from-blue-50 to-blue-100 shadow-xl rounded-xl overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl transition-shadow duration-300 ease-in-out">
              <div className="relative pt-10">
                <img
                  src={processImage1}
                  alt="Step 1"
                  className="w-full h-28 object-contain rounded-t-xl"
                />
              </div>
              <div className="p-8">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">
                  Step 1: Create Your Profile
                </h2>
                <p className="text-gray-600 text-base">
                  Sign up and set up your profile. Highlight your skills,
                  interests, and showcase your work.
                </p>
              </div>
            </div>

            {/* Step 2 */}
            <div className="relative bg-gradient-to-r from-green-50 to-green-100 shadow-xl rounded-xl overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl transition-shadow duration-300 ease-in-out">
              <div className="relative pt-10">
                <img
                  src={processImage2}
                  alt="Step 2"
                  className="w-full h-28 object-contain rounded-t-xl"
                />
              </div>
              <div className="p-8">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">
                  Step 2: Explore Opportunities
                </h2>
                <p className="text-gray-600 text-base">
                  Browse through a wide range of opportunities tailored to
                  influencers, models, and artists.
                </p>
              </div>
            </div>

            {/* Step 3 */}
            <div className="relative bg-gradient-to-r from-purple-50 to-purple-100 shadow-xl rounded-xl overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl transition-shadow duration-300 ease-in-out">
              <div className="relative pt-10">
                <img
                  src={processImage3}
                  alt="Step 3"
                  className="w-full h-28 object-contain rounded-t-xl"
                />
              </div>
              <div className="p-8">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">
                  Step 3: Connect & Collaborate
                </h2>
                <p className="text-gray-600 text-base">
                  Engage with potential clients, brands, and other
                  professionals. Start collaborations and projects.
                </p>
              </div>
            </div>

            {/* Step 4 */}
            <div className="relative bg-gradient-to-r from-yellow-50 to-yellow-100 shadow-xl rounded-xl overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl transition-shadow duration-300 ease-in-out">
              <div className="relative pt-10">
                <img
                  src={processImage4}
                  alt="Step 4"
                  className="w-full h-28 object-contain rounded-t-xl"
                />
              </div>
              <div className="p-8">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">
                  Step 4: Achieve Your Goals
                </h2>
                <p className="text-gray-600 text-base">
                  Utilize our platform’s tools and resources to grow your
                  presence and advance your career.
                </p>
              </div>
            </div>
          </div>


        </div>
        <div className="bg-[#0e243f] py-10 mt-16">
          <div className="container mx-auto">
            <div className="md:flex justify-between items-center">
              <div className="text-center md:text-left">
                <h2 className="md:text-3xl text-2xl font-semibold text-white dark:text-white mb-2">
                  Ready to Get Started?
                </h2>
                <p className="md:text-md text-sm text-white mb-4 md:mb-0">
                  Join us today to elevate your brand with targeted campaigns and
                  impactful collaborations.
                </p>
              </div>
              <div className="text-center md:text-left">
                <Link
                  to="/sign-up"
                  className="inline-block px-10 py-4 bg-themecolor1 text-white font-semibold rounded-full shadow-xl hover:bg-white hover:text-black transition-colors duration-300"
                >
                  Sign Up Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
