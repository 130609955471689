import axios from "axios";
import Config from "./Config";
import { toast } from "react-toastify";
const { API_BASE_URL } = Config;

// --------------------------------get Influencer by Category-----------------------
// https://www.model.easyshadi.com/front/api/vendor/interest/6
export const getInfluencerBySubCategoryId = async (CategoryId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/influencer/category/${CategoryId}`
    );
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
// --------------------------------get popular-influencer by Category-----------------------
// https://www.admin.viralkar.com/front/api/popular-influencer
export const getPopulatInfluencer = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/popular-influencer`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------get popular-influencer by Category-----------------------
// https://www.admin.viralkar.com/front/api/popular-influencer
export const getAllInfluencer = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/influencer`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------get Influencer by Id-----------------------
// https://www.model.easyshadi.com/front/api/vendor/profileImage/8
export const getInfluencerById = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/influencer/${Id}`);
    if (response.data.status === true) {
      return response.data.responseData[0];
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------get getAllModel by Category-----------------------
// https://www.admin.viralkar.com/front/api/model
export const getAllModel = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/model`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------get Influencer by Id-----------------------
// https://www.model.easyshadi.com/front/api/vendor/profileImage/8
export const getModelById = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/model/${Id}`);
    if (response.data.status === true) {
      return response.data.responseData[0];
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------get getAllModel by Category-----------------------
// https://www.admin.viralkar.com/front/api/model
export const getAllArtist = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/artist`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------get Influencer by Id-----------------------
// https://www.model.easyshadi.com/front/api/vendor/profileImage/8
export const getArtistById = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/artist/${Id}`);
    if (response.data.status === true) {
      return response.data.responseData[0];
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
