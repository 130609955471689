import React from "react";
import Breadcrumb from "../../Breadcumb/Breadcumb";
import TermsCoditionBanner from "../../Images/TermsConditionBanner.jpeg";
import { BsDot } from "react-icons/bs";

const TermsCondition = () => {
  const breadcrumbItems = [
    {
      text: " Terms & Conditions",
    },
  ];

  const termsData = [
    {
      title: "Acceptance of Terms",
      content: [
        "By creating an account or using the Platform, you agree to these Terms, our Privacy Policy, and any other policies or guidelines posted on the Platform.",
        "If you do not agree with these Terms, do not use the Platform.",
      ],
    },
    {
      title: "Eligibility",
      content: [
        {
          heading: "Age Requirement:",
          items: [
            "For Adults: You must be at least 18 years old to create an account and use the Platform without any restrictions.",
            "For Minors: If you are under 18 years old but at least 13 years old, you may register as a model with the express consent of a parent or legal guardian. Parental consent is required for all minors to create and maintain an account on the Platform.",
          ],
        },
        {
          heading: "Parental Consent for Minors:",
          items: [
            "Registration: When registering a minor as a model, the parent or legal guardian must complete a consent form and provide their contact details, including a valid email address and phone number.",
            "Verification: The parent or legal guardian will receive an email verification link to confirm their consent. The minor’s account will remain inactive until the consent is verified.",
            "Responsibilities: The parent or legal guardian is responsible for monitoring and managing the minor’s use of the Platform. They must ensure that the minor complies with these Terms and any other applicable guidelines.",
          ],
        },
        {
          heading: "Accurate Information:",
          items: [
            "You must provide accurate and complete information during the registration process. Any false or misleading information may result in account suspension or termination.",
          ],
        },
        {
          heading: "Account Management:",
          items: [
            "If you are a parent or legal guardian of a minor, you are responsible for managing the minor’s account and ensuring compliance with all applicable laws and regulations.",
          ],
        },
      ],
    },
    {
      title: "Account Registration and Management",
      content: [
        "Account Creation: You must register and create an account to use the Platform. Provide accurate and up-to-date information.",
        "Account Security: You are responsible for maintaining the confidentiality of your account and password. Notify us immediately of any unauthorized use.",
      ],
    },
    {
      title: "Content and Intellectual Property",
      content: [
        "Content Ownership: You retain ownership of your content, including images, videos, and text (collectively, “Content”), uploaded to the Platform. By uploading Content, you grant ViralKar.com a non-exclusive, royalty-free, perpetual, and worldwide license to use, display, and distribute your Content on the Platform.",
        "Content Guidelines: Ensure that your Content complies with our content guidelines and does not infringe on any third-party rights or violate any laws.",
      ],
    },
    {
      title: "Platform Use",
      content: [
        "Permitted Use: Use the Platform only for lawful purposes and in accordance with these Terms. You may not use the Platform for any illegal or unauthorized activities.",
        "Prohibited Activities: Do not engage in any activity that disrupts or interferes with the Platform or its services, including but not limited to spamming, phishing, or transmitting malware.",
      ],
    },
    {
      title: "Collaboration and Opportunities",
      content: [
        "Opportunities: ViralKar.com may facilitate connections and collaborations between users and brands or other users. We do not guarantee any specific results or opportunities.",
        "Responsibilities: When engaging in collaborations or accepting opportunities, you are responsible for fulfilling your obligations and agreements.",
      ],
    },
    {
      title: "Fees and Payments",
      content: [
        "Fees: The Platform is free to join, but certain features or services may incur fees. Details of any fees will be provided before you commit to any paid services.",
        "Payments: If applicable, payments will be processed according to the terms provided by ViralKar.com. You are responsible for any taxes or additional charges related to payments.",
      ],
    },
    {
      title: "Termination",
      content: [
        "Termination by You: You may terminate your account at any time by contacting our support team.",
        "Termination by Us: We reserve the right to suspend or terminate your account if you violate these Terms or engage in inappropriate conduct.",
      ],
    },
    {
      title: "Disclaimers and Limitation of Liability",
      content: [
        "Disclaimer: The Platform is provided on an “as-is” and “as-available” basis. We make no warranties or representations about the Platform’s functionality or reliability.",
        "Limitation of Liability: ViralKar.com is not liable for any indirect, incidental, or consequential damages arising from your use of the Platform. Our liability is limited to the maximum extent permitted by law.",
      ],
    },
    {
      title: "Indemnification",
      content: [
        "You agree to indemnify and hold harmless ViralKar.com, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses arising from your use of the Platform or violation of these Terms.",
      ],
    },
    {
      title: "Governing Law",
      content: [
        "These Terms are governed by and construed in accordance with the laws of [Insert Jurisdiction]. Any disputes arising from these Terms or your use of the Platform will be resolved in the courts of [Insert Jurisdiction].",
      ],
    },
    {
      title: "Changes to Terms",
      content: [
        "We may update these Terms from time to time. We will notify you of any significant changes by posting the updated Terms on the Platform. Your continued use of the Platform after any changes constitutes your acceptance of the revised Terms.",
      ],
    },
    {
      title: "Contact Us",
      content: [
        "If you have any questions or concerns about these Terms, please contact us at:",
        "ViralKar.com Support Team",
        "Email: support@viralkar.com",
        "Phone: +91 1234567890",
      ],
    },
  ];

  return (
    <div>
      <Breadcrumb
        items={breadcrumbItems}
        backgroundImage={TermsCoditionBanner}
      />

      <main className="bg-gray-100 dark:bg-darkmode min-h-screen py-12 px-4">
        {/* Content Section */}
        <section className="container mx-auto bg-white  dark:bg-slate-950 dark:border dark:text-white dark:border-gray-500 lg:p-8 p-4 rounded-lg shadow-lg">
          <div className="mb-5">
            <h1 className="text-xl font-semibold mb-4">
              Terms and Conditions
            </h1>
            <p className="text-base">
              Please read these terms and conditions carefully before using our
              service.
            </p>
          </div>
          {termsData.map((section, index) => (
            <div key={index} className="my-2">
              <h2 className="text-xl text-gray-800 dark:text-gray-200 mb-4">
                {section.title}
              </h2>
              {section.content.map((paragraph, idx) =>
                typeof paragraph === "object" ? (
                  <div key={idx} className="mb-4">
                    <h3 className="text-xl text-gray-700 dark:text-gray-200 mb-2">
                      {paragraph.heading}
                    </h3>
                    <ul className="list-disc pl-6 text-gray-600 dark:text-gray-100 ">
                      {paragraph.items.map((item, itemIdx) => (
                        <li key={itemIdx} className="text-sm">  
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div
                    key={idx}
                    className="text-gray-600 dark:text-gray-200 leading-relaxed text-sm flex items-baseline"
                  >
                    <span className="text-xl pe-2  ">•</span>
                    {paragraph}  
                  </div>
                )
              )}
            </div>
          ))}
        </section>
      </main>
    </div>
  );
};

export default TermsCondition;
