/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DashboardMenu from "../../DashboardMenu";
import ScrollToTop from "../ScrollToTop";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormLoader from "../../../common/FormLoader";
import Config from "../../../API/Config";
import { ModelGetById, UpdateModelInfById } from "../../../API/ModelSignUpAPI";
import { useData } from "../../../Context/DataContext ";
import { format, parse } from "date-fns";
import usePageSEO from "../../usepageSEO/Index";

const ModelProfile = () => {
  usePageSEO(
    "Viral kar | Dashboard", 
    "Viral kar | Dashboard", 
    ["Viral kar | Dashboard"]
  );

  const [ThumbImagePreview, setThumbImagePreview] = useState();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const Id = Config.getId(); // Assuming Config is defined and imported properly
  const Status = Config.getStatus();
  const { CityData } = useData(); // Assuming useData provides the necessary data

  // Formik Initialization
  const formik = useFormik({
    initialValues: {
      Name: "",
      Email: "",
      Phone: "",
      DOB: "",
      Type: "",
      Gender: "",
      CityId: "",
      Height: "",
      Weight: "",
      Age: "",
      Bust: "",
      Waist: "",
      Hips: "",
      Instagram: "",
      Facebook: "",
      Youtube: "",
      Twitter: "",
      Blog: "",
      Roposo: "",
      MXTakatak: "",
      ProfileImage: "",
      CompositeCardImages: [],
      VendorImages: [],
      HidImg: "",
    },

    onSubmit: async (values) => {
      setIsFormLoading(true);
      try {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          if (key !== "CompositeCardImages" && key !== "VendorImages") {
            formData.append(key, value);
          }
        });

        // Append CompositeCardImages
        values.CompositeCardImages.forEach((file) => {
          formData.append("CompositeCardImages", file);
        });

        // Append VendorImages
        values.VendorImages.forEach((file) => {
          formData.append("VendorImages", file);
        });

        const result = await UpdateModelInfById(Id, formData);
        FatchData(); // Refetch data after submission to refresh form values
      } catch (error) {
        console.error("Error updating model information:", error);
      } finally {
        setIsFormLoading(false);
      }
    },
  });

  // Fetch data function
  const FatchData = async () => {
    try {
      const result = await ModelGetById(Id);

      // Check and set profile image
      if (result.ProfileImage) {
        setThumbImagePreview(result.Path + result.ProfileImage);
      }

      // Format the date correctly for the form
      const parsedDate = result.DOB ? new Date(result.DOB) : new Date(); 
      const formattedDate = format(parsedDate, "yyyy-MM-dd");

      // Set formik values with the fetched data
      formik.setValues({
        Name: result.Name || "",
        Email: result.Email || "",
        Phone: result.Phone || "",
        DOB: formattedDate || "",
        Type: result.Type || "",
        Gender: result.Gender || "",
        CityId: result.CityId || "",
        Height: result.Height || "",
        Weight: result.Weight || "",
        Age: result.Age || "",
        Bust: result.Bust || "",
        Waist: result.Waist || "",
        Hips: result.Hips || "",
        Instagram: result.Instagram || "",
        Facebook: result.Facebook || "",
        Youtube: result.YouTube || "",
        Twitter: result.Twitter || "",
        Blog: result.Blog || "",
        Roposo: result.Roposo || "",
        MXTakatak: result.MXTakatak || "",
        ProfileImage: result.ProfileImage || "",
        CompositeCardImages: [], // Assuming images are handled separately
        VendorImages: [], // Assuming images are handled separately
        HidImg: result.Hid_Image || "",
      });

      sessionStorage.setItem("getvendorStatus", result.Status);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    FatchData();
  }, [Id]);

  useEffect(() => {
    if (ThumbImagePreview) {
      sessionStorage.setItem("ProfileImage", ThumbImagePreview);
    }
  }, [ThumbImagePreview]);

    
  return (
    <div className="bg-dark">
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="">
        <ScrollToTop />
        <div className="container mx-auto">
          <div className="md:flex py-5 md:py-14 px-3 md:gap-10 ">
          <div className="md:w-1/3 mb-5 md:mb-0">
          <DashboardMenu />
          </div>
            {/* ===============================Nav Pilss data ====================================*/}
            <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4">
              <div className="bg-dark-light">
                <form onSubmit={formik.handleSubmit}>
                  <input
                    type="hidden"
                    name="HidImg"
                    value={formik.values.HidImg}
                  />
                  <div className="">
                    <div className="rounded-lg bg-dark-light p-5 dark:border border-white">
                      {Status === 0 ? (
                        <div
                          class="bg-orange-100 border-t-2 mb-4 text-sm border-orange-500 rounded-b text-orange-900 px-4 py-2 shadow-md"
                          role="alert"
                        >
                          <div class="flex justify-center">
                            <p class="font-semibold text-sm">
                              Your Profile is Under Review
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          class="bg-green-100 border-t-4 mb-4 text-sm border-green-500 rounded-b text-green-900 px-4 py-2 shadow-md"
                          role="alert"
                        >
                          <div class="flex justify-center">
                            <p class="font-bold">Your Profile is Approved</p>
                          </div>
                        </div>
                      )}
                      <p className="text-center text-xl py-1.5 mb-3 bg-themebgcolor3 text-white dark:text-white rounded-lg">
                        Details
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="my-1">
                          <label className="text-base" for="">
                            Name <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Name}
                            placeholder="Name"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                          {formik.touched.Name && formik.errors.Name ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Name}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Email
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="email"
                            name="Email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Email}
                            disabled
                            placeholder="Email Address"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder  mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                          {formik.touched.Email && formik.errors.Email ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Email}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Phone <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Phone"
                            disabled
                            readOnly
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Phone}
                            placeholder="Phone"
                            className=" px-4 py-3 w-full focus:outline-none  mt-3 placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                          {formik.touched.Phone && formik.errors.Phone ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Phone}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            DOB <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="date"
                            name="DOB"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.DOB}
                            placeholder="DOB"
                            className=" px-4 py-3 w-full focus:outline-none  mt-3 placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                          {formik.touched.DOB && formik.errors.DOB ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.DOB}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Profile Picture{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="file"
                            name="ProfileImage"
                            onChange={(event) => {
                              formik.setFieldValue(
                                "ProfileImage",
                                event.currentTarget.files[0]
                              );
                            }}
                            onBlur={formik.handleBlur}
                            placeholder="Business Country"
                            className="text-sm text-grey-500 file:mr-5  mt-3 file:py-1.5 file:px-5 file:rounded-full file:border-0 file:text-md file:font-semibold  file:text-white
                              file:bg-gradient-to-r file:from-themecolor file:to-black hover:file:cursor-pointer hover:file:opacity-80 py-1.5 w-full focus:outline-none border-0 rounded"
                          />
                          {formik.touched.ProfileImage &&
                          formik.errors.ProfileImage ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.ProfileImage}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Portfolio Images
                            <span className="text-red-500 text-sm">
                              *(Multiple)
                            </span>
                          </label>
                          <div className="relative">
                            <input
                              type="file"
                              name="VendorImages"
                              multiple
                              onChange={(event) => {
                                const files = Array.from(
                                  event.currentTarget.files
                                );
                                formik.setFieldValue("VendorImages", files);
                              }}
                              onBlur={formik.handleBlur}
                              placeholder="Business Country"
                              className="text-sm text-grey-500 file:mr-5 file:py-1.5  mt-3 file:px-5 file:rounded-full file:border-0 file:text-md file:font-semibold  file:text-white
 file:bg-gradient-to-r file:from-themecolor file:to-black hover:file:cursor-pointer hover:file:opacity-80 py-1.5 w-full focus:outline-none mt-3 border-0 rounded "
                            />
                          </div>

                          {formik.touched.VendorImages &&
                          formik.errors.VendorImages ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.VendorImages}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label className="text-base">
                            Select City <span className="text-red-500">*</span>
                          </label>
                          <select
                            name="CityId"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.CityId}
                            className=" px-4 py-3 w-full focus:outline-none  mt-3 placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          >
                            {CityData?.map((val, index) => {
                              return (
                                <option key={index} value={val.Id}>
                                  {val.Title}
                                </option>
                              );
                            })}
                          </select>
                          {formik.touched.CityId && formik.errors.CityId ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.CityId}
                            </div>
                          ) : null}
                        </div>

                        <div className="my-1">
                          <label className="text-base">
                            Gender <span className="text-red-500">*</span>
                          </label>
                          <div className="flex items-center mt-2 dark:bg-[#1e1e1e] px-2 py-2.5">
                            <div className="mr-3">
                              <input
                                type="radio"
                                id="Male"
                                name="Gender"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="M"
                                checked={formik.values.Gender === "M"}
                                className="mr-2"
                              />
                              <label htmlFor="Male">Male</label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id="Female"
                                name="Gender"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="F"
                                checked={formik.values.Gender === "F"}
                                className="mr-3"
                              />
                              <label htmlFor="Female">Female</label>
                            </div>
                          </div>

                          {formik.touched.Gender && formik.errors.Gender ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Gender}
                            </div>
                          ) : null}
                        </div>

                        <div className="my-1">
                          <label className="text-base">
                            Type <span className="text-red-500">*</span>
                          </label>
                          <div className="flex items-center mt-2 dark:bg-[#1e1e1e] px-2 py-2.5">
                            <div className="mr-3">
                              <input
                                type="radio"
                                id="Model"
                                name="Type"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="M"
                                checked={formik.values.Type === "M"}
                                className="mr-2"
                              />
                              <label htmlFor="Model">Model</label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id="Influencer"
                                name="Type"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="I"
                                className="mr-3"
                                checked={formik.values.Type === "I"}
                              />
                              <label htmlFor="Influencer">Influencer </label>
                            </div>
                          </div>

                          {formik.touched.Type && formik.errors.Type ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Type}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          {formik.values.Type === "I" ? (
                            <div className="my-1">
                              <label for="">
                                Composite Card{" "}
                                <span className="text-red-500 text-sm">
                                  *(Atleast 5 Image Upload)
                                </span>
                              </label>
                              <div className="relative">
                                <input
                                  type="file"
                                  name="CompositeCardImages"
                                  multiple
                                  onChange={(event) => {
                                    const files = Array.from(
                                      event.currentTarget.files
                                    );
                                    formik.setFieldValue(
                                      "CompositeCardImages",
                                      files
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  placeholder="Business Country"
                                  className="dark:bg-[#1e1e1e] p-4 py-2 w-full focus:outline-none my-1.5 border border-zinc-600 "
                                />
                              </div>
                              {formik.touched.CompositeCardImages &&
                              formik.errors.CompositeCardImages ? (
                                <div className="text-red-500 text-sm">
                                  {formik.errors.CompositeCardImages}
                                </div>
                              ) : null}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <p className="text-center text-xl py-1.5 mb-3 bg-themebgcolor3 text-white dark:text-white rounded-lg">
                        Other Details
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div className="my-1">
                          <label for="">
                            Height <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Height"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Height}
                            placeholder="Height"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Height && formik.errors.Height ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Height}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Weight <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Weight"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Weight}
                            placeholder="Weight"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Weight && formik.errors.Weight ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Weight}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Age <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Age"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Age}
                            placeholder="Age"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Age && formik.errors.Age ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Age}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Bust <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Bust"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Bust}
                            placeholder="Bust"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Bust && formik.errors.Bust ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Bust}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Waist <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Waist"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Waist}
                            placeholder="Waist"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Waist && formik.errors.Waist ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Waist}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Hips <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Hips"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Hips}
                            placeholder="Hips"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Hips && formik.errors.Hips ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Hips}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <p className="text-center text-xl py-1.5 mb-3 bg-themebgcolor3 text-white dark:text-white rounded-lg mt-4">
                        Social Media Details
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div className="my-1">
                          <label for="">
                            Instagram <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Instagram"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Instagram}
                            placeholder="Instagram"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Instagram &&
                          formik.errors.Instagram ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Instagram}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Facebook <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Facebook"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Facebook}
                            placeholder="Facebook"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Facebook && formik.errors.Facebook ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Facebook}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Youtube <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Youtube"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Youtube}
                            placeholder="Youtube"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Youtube && formik.errors.Youtube ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Youtube}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Twitter <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Twitter"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Twitter}
                            placeholder="Twitter"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder  border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Twitter && formik.errors.Twitter ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Twitter}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">Blog</label>
                          <input
                            type="text"
                            name="Blog"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Blog}
                            placeholder="Blog"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Blog && formik.errors.Blog ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Blog}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">Roposo</label>
                          <input
                            type="text"
                            name="Roposo"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Roposo}
                            placeholder="Roposo"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Roposo && formik.errors.Roposo ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Roposo}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">MXTakatak</label>
                          <input
                            type="text"
                            name="MXTakatak"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.MXTakatak}
                            placeholder="MXTakatak"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.MXTakatak &&
                          formik.errors.MXTakatak ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.MXTakatak}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="flex justify-center my-3">
                        <button
                          type="submit"
                          className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-10"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModelProfile