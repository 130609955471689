import React from "react";
 import TermsBanner from "../../Images/TermsConditionBanner.jpeg"; // Adjust the path as needed
import Breadcrumb from "../../Breadcumb/Breadcumb";

const TermsAndConditions = () => {
  const breadcrumbItems = [
    {
      text: "Privacy Ploicy",
    },
  ];

  // Define the terms and conditions data
  const termsData = [
    {
      title: 'Acceptance of Terms',
      content: [
        'By creating an account or using the Platform, you agree to these Terms, our Privacy Policy, and any other policies or guidelines posted on the Platform.',
        'If you do not agree with these Terms, do not use the Platform.',
      ],
    },
    {
      title: 'Eligibility',
      content: [
        {
          heading: 'Age Requirement:',
          items: [
            'For Adults: You must be at least 18 years old to create an account and use the Platform without any restrictions.',
            'For Minors: If you are under 18 years old but at least 13 years old, you may register as a model with the express consent of a parent or legal guardian. Parental consent is required for all minors to create and maintain an account on the Platform.',
          ],
        },
        {
          heading: 'Parental Consent for Minors:',
          items: [
            'Registration: When registering a minor as a model, the parent or legal guardian must complete a consent form and provide their contact details, including a valid email address and phone number.',
            'Verification: The parent or legal guardian will receive an email verification link to confirm their consent. The minor’s account will remain inactive until the consent is verified.',
            'Responsibilities: The parent or legal guardian is responsible for monitoring and managing the minor’s use of the Platform. They must ensure that the minor complies with these Terms and any other applicable guidelines.',
          ],
        },
        {
          heading: 'Accurate Information:',
          items: [
            'You must provide accurate and complete information during the registration process. Any false or misleading information may result in account suspension or termination.',
          ],
        },
        {
          heading: 'Account Management:',
          items: [
            'If you are a parent or legal guardian of a minor, you are responsible for managing the minor’s account and ensuring compliance with all applicable laws and regulations.',
          ],
        },
      ],
    },
    // Add more sections as needed
  ];

  return (
    <div>
      <Breadcrumb items={breadcrumbItems} backgroundImage={TermsBanner} />
      <main className="bg-gray-100 dark:bg-darkmode min-h-screen">
        <section className="py-12 px-4">
          <div className="container mx-auto bg-white dark:bg-slate-950 dark:border dark:border-gray-500 lg:p-8 p-4 rounded-lg shadow-lg">
            <div className="space-y-8">
              {termsData.map((section, index) => (
                <div key={index} className="mb-8">
                  <h2 className="text-2xl font-semibold text-gray-700 dark:text-white mb-4">{section.title}</h2>
                  {section.content.map((paragraph, idx) =>
                    typeof paragraph === 'object' ? (
                      <div key={idx} className="mb-4">
                        <h3 className="text-lg font-semibold text-gray-700 dark:text-white mb-2">{paragraph.heading}</h3>
                        <ul className="list-disc lg:pl-6 pl-4 text-gray-600 dark:text-slate-200 space-y-2">
                          {paragraph.items.map((item, itemIdx) => (
                            <li key={itemIdx} className="flex   text-sm lg:text-base">
                              <span className="text-xl font-bold pe-2 ">•</span>{item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <p key={idx} className="text-gray-600 dark:text-slate-200 leading-relaxed mb-4 text-sm lg:text-base">{paragraph}</p>
                    )
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default TermsAndConditions;
