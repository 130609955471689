import axios from "axios";
import Config from "./Config";
const { API_BASE_URL } = Config;

// ------------------------------get pages by Slug-------------------
// htt[s://www.model.easyshadi.com/front/api/pages/${Slug}

export const GetPageBySlug = async (Slug) => {
  try {
    const responce = await axios.get(`${API_BASE_URL}/pages/${Slug}`);
    if (responce.data.status === true) {
      return responce.data.responseData;
    } else {
      throw new Error(responce.data.message);
    }
  } catch (error) {
    throw error;
  }
};
