/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FemaleInnerbanner from "../../Images/contact-back.jpg";
import BreadCrumb from "../../Breadcumb/Breadcumb";
import titleimage from "../../Images/title-icon.png";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import { AddVendorInquiry, CreateCompositeCard } from "../../API/ModelInfMain";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormLoader from "../../common/FormLoader";
import ScrollToTop from "./ScrollToTop";
import ClipLoader from "react-spinners/FadeLoader";
import usePageSEO from "../usepageSEO/Index";
import { getArtistById, getInfluencerById } from "../../API/NewInfluencerAPI";

const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Name cannot contain numbers")
    .required("Name is required"),

  Email: Yup.string().email("Invalid email").required("Email is required"),

  Phone: Yup.string()
    .matches(/^[0-9]+$/, "Invalid mobile number")
    .required("Number is required"),
  Message: Yup.string().required("Message is required"),
});

const ArtistDetail = () => {
  const topinfluencer = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { Id } = useParams();
  const [ArtistData, setArtistData] = useState(null);
  const [CompositeCard, setCompositeCard] = useState("");
  const [loading, setLoading] = useState(true); // Loading state

  const getData = async () => {
    try {
      const results = await getArtistById(Id);
      setArtistData(results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const getCompositeCarddata = async () => {
    try {
      const results = await CreateCompositeCard(Id, "I");
      setCompositeCard(results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // getCompositeCarddata();
  }, [Id]);
  console.log(getInfluencerById);

  usePageSEO(
    ArtistData ? `Viral kar | ${ArtistData.Name}` : "Viral kar | Influencer", // Use page title for SEO if available
    ArtistData ? `Viral kar | ${ArtistData.Name}` : "Viral kar | Influencer", // Use page description for SEO if available
    [ArtistData ? `Viral kar | ${ArtistData.Name}` : "Viral kar | Influencer"] // No keywords provided in this example
  );

  const [isFormLoading, setIsFormLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      VendorId: Id,
      Name: "",
      Email: "",
      Phone: "",
      Message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await AddVendorInquiry(values);
        if (response.status === true) {
          actions.resetForm();
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  const breadcrumbItems = [
    {
      text: ArtistData?.Name,
    },
  ];
  const backgroundImage = FemaleInnerbanner;
  return (
    <div>
      <div>
        {isFormLoading && <FormLoader loading={isFormLoading} />}
        <ScrollToTop />
        <BreadCrumb items={breadcrumbItems} backgroundImage={backgroundImage} />
        <div className=" dark:bg-darkmode dark:text-white pt-10">
          <div className="container mx-auto px-2 xl:px-0 lg:px-4">
            {loading ? (
              <div className="flex justify-center items-center my-40">
                <ClipLoader color={"#d00067"} loading={loading} size={40} />
              </div>
            ) : (
              <React.Fragment>
                <div className="lg:px-32">
                  <h1 className="page-title text-3xl text-center pb-1 dark:text-white">
                    Artist Details
                  </h1>

                  <img
                    src={titleimage}
                    alt=""
                    className="mx-auto mt-2 title-icon"
                  />
                </div>

                <div className="md:flex mt-10 lg:gap-10">
                  <div className=" col-span-2 ">
                    <div className="models-box relative">
                      <div className="relative overflow-hidden  ">
                        <img
                          src={ArtistData?.Path + ArtistData?.ProfileImage}
                          alt=""
                          className="md:h-[550px] w-full md:object-cover object-cover lg:object-center border rounded-md border-gray-200 bg-white p-2"
                        />
                        <div className="absolute bottom-1.5 right-1.5 flex flex-col">
                          <Link
                            to={
                              ArtistData?.Instragram || "https://instagram.com"
                            }
                            target="_blank"
                            className="mb-1"
                          >
                            <h2 className="bg-instagram  duration-200 text-white p-2.5 text-lg  drop-shadow-lg">
                              <FaInstagram />
                            </h2>
                          </Link>
                          <Link
                            to={ArtistData?.Facebook || "https://facebook.com"}
                            target="_blank"
                            className="mb-1"
                          >
                            <h2 className="bg-instagram  duration-200 text-white p-2.5 text-lg  drop-shadow-lg">
                              <FaFacebookF />
                            </h2>
                          </Link>
                          <Link
                            to={ArtistData?.Twitter || "https://x.com"}
                            target="_blank"
                            className="mb-1"
                          >
                            <h2 className="bg-instagram  duration-200 text-white p-2.5 text-lg  drop-shadow-lg">
                              <FaXTwitter />
                            </h2>
                          </Link>
                          
                          <Link
                            to={ArtistData?.YouTube || "https://youtube.com"}
                            target="_blank"
                            className="mb-1"
                          >
                            <h2 className="bg-instagram  duration-200 text-white p-2.5 text-lg  drop-shadow-lg">
                              <FaYoutube />
                            </h2>
                          </Link>
                          <Link
                            to={ArtistData?.LinkedIn || "https://linkedin.com/"}
                            target="_blank"
                            className="mb-1"
                          >
                            <h2 className="bg-instagram  duration-200 text-white p-2.5 text-lg  drop-shadow-lg">
                              <FaLinkedin />
                            </h2>
                          </Link>

                          <Link
                            to={
                              ArtistData?.TikTok || "https://www.tiktok.com/en/"
                            }
                            target="_blank"
                          >
                            <h2 className="bg-instagram  duration-200 text-white p-2.5 text-lg  drop-shadow-lg">
                              <FaTiktok />
                            </h2>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="md:w-3/5">
                    <div className="grid grid-cols-1 xl:gap-4 lg:gap-0">
                      <div className="">
                        <div className="bg-white p-5 rounded-lg dark:border-gray-100 shadow-lg dark:bg-white border dark:text-white">
                          <table className="table-auto w-full">
                            <tbody>
                              {ArtistData?.Name && (
                                <tr className="flex flex-col md:flex-row md:justify-between border p-2">
                                  <td className="text-left text-md font-medium text-black my-auto md:w-1/3">
                                    Name :
                                  </td>
                                  <td className="my-auto text-sm text-gray-600 md:w-2/3 break-all">
                                    {ArtistData?.Name}{" "}
                                    <span className="text-gray-400 ml-1 text-[12px] font-normal">
                                      {ArtistData?.Gender === "F"
                                        ? "(Female)"
                                        : ArtistData?.Gender === "M"
                                        ? "(Male)"
                                        : ArtistData?.Gender === "K"
                                        ? "(Kids)"
                                        : ""}
                                    </span>
                                  </td>
                                </tr>
                              )}
                              {ArtistData?.ArtistName && (
                                <tr className="flex flex-col md:flex-row md:justify-between border p-2">
                                  <td className="text-left text-md font-medium text-black my-auto md:w-1/3">
                                    Artist Name :
                                  </td>
                                  <td className="my-auto text-sm text-gray-600 md:w-2/3 break-all">
                                    {ArtistData?.ArtistName}
                                  </td>
                                </tr>
                              )}
                              {ArtistData?.Email && (
                                <tr className="flex flex-col md:flex-row md:justify-between border p-2">
                                  <td className="text-left text-md font-medium text-black my-auto md:w-1/3">
                                    Email :
                                  </td>
                                  <td className="my-auto text-sm text-gray-600 md:w-2/3 break-all">
                                    {ArtistData?.Email}
                                  </td>
                                </tr>
                              )}
                              {ArtistData?.Phone && (
                                <tr className="flex flex-col md:flex-row md:justify-between border p-2">
                                  <td className="text-left text-md font-medium text-black my-auto md:w-1/3">
                                    Phone :
                                  </td>
                                  <td className="my-auto text-sm text-gray-600 md:w-2/3 break-all">
                                    {ArtistData?.Phone}
                                  </td>
                                </tr>
                              )}
                              {ArtistData?.DOB && (
                                <tr className="flex flex-col md:flex-row md:justify-between border p-2">
                                  <td className="text-left text-md font-medium text-black my-auto md:w-1/3">
                                    DOB :
                                  </td>
                                  <td className="my-auto text-sm text-gray-600 md:w-2/3 break-all">
                                    {ArtistData?.DOB
                                      ? new Date(
                                          ArtistData?.DOB
                                        ).toLocaleDateString("en-GB")
                                      : ""}
                                  </td>
                                </tr>
                              )}
                              {ArtistData?.CityName && (
                                <tr className="flex flex-col md:flex-row md:justify-between border p-2">
                                  <td className="text-left text-md font-medium text-black my-auto md:w-1/3">
                                    City :
                                  </td>
                                  <td className="my-auto text-sm text-gray-600 md:w-2/3 break-all">
                                    {ArtistData?.CityName}
                                  </td>
                                </tr>
                              )}
                              {ArtistData?.ChildCategoryNames && (
                                <tr className="flex flex-col md:flex-row md:justify-between border p-2">
                                  <td className="text-left text-md font-medium text-black my-auto md:w-1/3">
                                    Category :
                                  </td>
                                  <td className="my-auto text-sm text-gray-600 md:w-2/3 break-all">
                                    {ArtistData?.ChildCategoryNames?.map(
                                      (val) => {
                                        return <>{val}, </>;
                                      }
                                    )}
                                  </td>
                                </tr>
                              )}
                              {ArtistData?.CollaborationNames && (
                                <tr className="flex flex-col md:flex-row md:justify-between border p-2">
                                  <td className="text-left text-md font-medium text-black my-auto md:w-1/3">
                                    Collaborations :
                                  </td>
                                  <td className="my-auto text-sm text-gray-600 md:w-2/3 break-all">
                                    {ArtistData?.CollaborationNames?.map(
                                      (val) => {
                                        return <>{val}, </>;
                                      }
                                    )}
                                  </td>
                                </tr>
                              )}
                              {ArtistData?.Exhibitions && (
                                <tr className="flex flex-col md:flex-row md:justify-between border p-2">
                                  <td className="text-left text-md font-medium text-black my-auto md:w-1/3">
                                    Exhibitions :
                                  </td>
                                  <td className="my-auto text-sm text-gray-600 md:w-2/3 break-all">
                                    {ArtistData?.Exhibitions}
                                  </td>
                                </tr>
                              )}
                              {ArtistData?.Experience && (
                                <tr className="flex flex-col md:flex-row md:justify-between border p-2">
                                  <td className="text-left text-md font-medium text-black my-auto md:w-1/3">
                                    Experience :
                                  </td>
                                  <td className="my-auto text-sm text-gray-600 md:w-2/3 break-all">
                                    {ArtistData?.Experience}
                                  </td>
                                </tr>
                              )}
                              {ArtistData?.PreviousProjects && (
                                <tr className="flex flex-col md:flex-row md:justify-between border p-2">
                                  <td className="text-left text-md font-medium text-black my-auto md:w-1/3">
                                    Previous Projects :
                                  </td>
                                  <td className="my-auto text-sm text-gray-600 md:w-2/3 break-all">
                                    {ArtistData?.PreviousProjects}
                                  </td>
                                </tr>
                              )}
                              {ArtistData?.CollaborationIndustries && (
                                <tr className="flex flex-col md:flex-row md:justify-between border p-2">
                                  <td className="text-left text-md font-medium text-black my-auto md:w-1/3">
                                    Collaboration Industries :
                                  </td>
                                  <td className="my-auto text-sm text-gray-600 md:w-2/3 break-all">
                                    {ArtistData?.CollaborationIndustries}
                                  </td>
                                </tr>
                              )}
                              {ArtistData?.Awards && (
                                <tr className="flex flex-col md:flex-row md:justify-between border p-2">
                                  <td className="text-left text-md font-medium text-black my-auto md:w-1/3">
                                    Awards:
                                  </td>
                                  <td className="my-auto text-sm text-gray-600 md:w-2/3 break-all">
                                    {ArtistData?.Awards}
                                  </td>
                                </tr>
                              )}
                              {ArtistData?.NotableWorks && (
                                <tr className="flex flex-col md:flex-row md:justify-between border p-2">
                                  <td className="text-left text-md font-medium text-black my-auto md:w-1/3">
                                    NotableWorks :
                                  </td>
                                  <td className="my-auto text-sm text-gray-600 md:w-2/3 break-all">
                                    {ArtistData?.NotableWorks}
                                  </td>
                                </tr>
                              )}
                              {ArtistData?.Website && (
                                <tr className="flex flex-col md:flex-row md:justify-between border p-2">
                                  <td className="text-left text-md font-medium text-black my-auto md:w-1/3">
                                    Website :
                                  </td>
                                  <td className="my-auto text-sm text-gray-600 md:w-2/3 break-all">
                                    <Link
                                      to={ArtistData?.Website}
                                      target="_blank"
                                    >
                                      {ArtistData?.Website}
                                    </Link>
                                  </td>
                                </tr>
                              )}
                              {ArtistData?.VideoUrl && (
                                <tr className="flex flex-col md:flex-row md:justify-between border p-2">
                                  <td className="text-left text-md font-medium text-black my-auto md:w-1/3">
                                    Video Url :
                                  </td>
                                  <td className="my-auto text-sm text-gray-600 md:w-2/3 break-all">
                                    <Link
                                      to={ArtistData?.VideoUrl}
                                      target="_blank"
                                    >
                                      {ArtistData?.VideoUrl}
                                    </Link>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    {ArtistData?.Bio && (
                      <div className="dark:bg-zinc-800 border border-gray-400 shadow-md rounded-md b p-6 mt-5">
                        <div className="flex justify-center space-x-2">
                          <p>{ArtistData?.Bio}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}
            <div className="dark:bg-zinc-800 border border-gray-400 shadow-md rounded-md dark:border-0 p-6 mt-10">
              <h1 className="text-4xl text-center font-extralight pb-4">
                Hire {ArtistData?.Name}
              </h1>
              <form onSubmit={formik.handleSubmit}>
                <div className="grid md:grid-cols-2 grid-cols-1 gap-4 ">
                  <div>
                    <label for="">
                      Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Name}
                      className="p-3 w-full dark:bg-neutral-500 dark:border-0 border rounded-md focus:outline-none focus:ring focus:border-blue-500 placeholder:text-black placeholder:text-sm"
                      placeholder="Enter Your Name"
                    />
                    {formik.touched.Name && formik.errors.Name ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Name}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <label for="">
                      Email <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      name="Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Email}
                      className="p-3 w-full dark:bg-neutral-500 dark:border-0 border rounded-md focus:outline-none focus:ring focus:border-blue-500 placeholder:text-black placeholder:text-sm"
                      placeholder="Enter Your Email"
                    />
                    {formik.touched.Email && formik.errors.Email ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Email}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="mt-5">
                  <label for="">
                    Phone <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="Phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Phone}
                    className="p-3 w-full dark:bg-neutral-500 dark:border-0 border rounded-md focus:outline-none focus:ring focus:border-blue-500 placeholder:text-black placeholder:text-sm"
                    placeholder="Enter Your Mobile"
                  />
                  {formik.touched.Phone && formik.errors.Phone ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.Phone}
                    </div>
                  ) : null}
                </div>
                <div className="mt-5">
                  <label for="">
                    Message <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    name="Message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Message}
                    rows="4"
                    className="w-full p-3 dark:bg-neutral-500 dark:border-0 border rounded-md focus:outline-none focus:ring focus:border-blue-500 placeholder:text-black placeholder:text-sm"
                    placeholder="Message"
                  ></textarea>
                  {formik.touched.Message && formik.errors.Message ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.Message}
                    </div>
                  ) : null}
                </div>

                <button
                  type="submit"
                  className="bg-themecolor p-2 rounded-md px-6 font-medium text-lg mt-4 border border-themecolor text-white   hover:bg-black"
                >
                  Send Message
                </button>
              </form>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-5 lg:mt-20 mt-10 lg:gap-5">
              {/*
               <div className="col-span-3">
                <h1 className="lg:text-4xl text-3xl text-center">
                  Recent Posts
                </h1>
                <img src={titleimage} alt="" className="mx-auto mt-2 title-icon" />
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-5 mt-4">
                  {femaleinnerdata.map((val, index) => (
                    <div className="models-box relative" key={index}>
                      <div className="relative overflow-hidden xl:h-[25rem] lg:h-[20.5rem] md:h-96 h-80">
                        <img
                          src={val.image}
                          alt=""
                          className="h-full w-full md:object-cover object-cover lg:object-center"
                        />
                      </div>
                      <div className="bg-black p-2 flex">
                        <div className="flex space-x-1 my-auto">
                          <h1 className="my-auto">
                            <FaHeart />
                          </h1>
                          <p className="my-auto">29.2k</p>
                        </div>
                        <div className="flex space-x-1 ml-auto my-auto">
                          <h1 className="my-auto">
                            <TbMessageCircle2Filled />
                          </h1>
                          <p className="my-auto">82</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-span-2 mt-4 lg:mt-0">
                <div className="bg-dark-light p-6 ">
                  <h1 className="text-3xl font-light text-center">
                    Profile Hashtags
                  </h1>
                  <ul className="flex justify-center flex-wrap space-x-3 mt-4">
                    <li className="border border-themecolor rounded-full p-1 px-2 my-2 font-semibold text-sm">
                      #Physiogel
                    </li>
                    <li className="border border-themecolor rounded-full p-1 px-2 my-2 font-semibold text-sm">
                      #SensitiveSkin
                    </li>
                    <li className="border border-themecolor rounded-full p-1 px-2 my-2 font-semibold text-sm">
                      #VillaHaleana
                    </li>
                    <li className="border border-themecolor rounded-full p-1 px-2 my-2 font-semibold text-sm">
                      #thailand
                    </li>
                    <li className="border border-themecolor rounded-full p-1 px-2 my-2 font-semibold text-sm">
                      #Malaiwana
                    </li>
                    <li className="border border-themecolor rounded-full p-1 px-2 my-2 font-semibold text-sm">
                      #travelwithshivesh
                    </li>
                    <li className="border border-themecolor rounded-full p-1 px-2 my-2 font-semibold text-sm">
                      #lohonoonmymind
                    </li>
                    <li className="border border-themecolor rounded-full p-1 px-2 my-2 font-semibold text-sm">
                      #zurich
                    </li>
                    <li className="border border-themecolor rounded-full p-1 px-2 my-2 font-semibold text-sm">
                      #DrySkin
                    </li>
                    <li className="border border-themecolor rounded-full p-1 px-2 my-2 font-semibold text-sm">
                      #ResidencesDuplex
                    </li>
                    <li className="border border-themecolor rounded-full p-1 px-2 my-2 font-semibold text-sm">
                      #elitehavens
                    </li>
                  </ul>
                </div>

              
              </div>
              */}
            </div>

            {/*
             <div className="mt-10 px-3 md:px-5 lg:px-0">
              <h1 className="lg:text-4xl text-3xl text-center">
                Similar Influencer
              </h1>
              <img
                src={titleimage}
                alt=""
                className="mx-auto mt-2 title-icon"
              />
              <div className="  z-40 mt-8">
                {ArtistData?.SimilarVendors?.length <= 5 ? (
                  <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
                    {ArtistData?.SimilarVendors?.map((val, index) => (
                      <div key={index} className="p-5">
                        <Link
                          to={`/influencer/${val.Id}`}
                          className="relative models-box cursor-pointer "
                        >
                          <img
                            src={val.ProfileImage}
                            alt=""
                            className="rounded-lg h-[500px] w-full object-cover"
                          />
                          <h2 className="absolute bottom-0 left-0 right-0 bg-themecolor1 p-3 text-center text-white text-2xl font-bold rounded-tl-none rounded-tr-none rounded-lg">
                            {val.Name}
                          </h2>

                          <div className="overlay absolute bottom-0 text-white w-full transition-opacity ease-linear duration-500 opacity-0 text-xl p-0 text-center">
                            <div className="bg-black px-4 py-1 text-left text-xl lg:text-2xl">
                              <p>{val.Name}</p>
                            </div>
                            <div className="flex items-center justify-between bg-black">
                              <div className="box-border flex items-center justify-between w-full bg-white text-black">
                                <div className="mx-auto leading-9">
                                  <h4 className="text-themecolor font-bold uppercase surface:font-semibold">
                                    Height
                                  </h4>
                                  <p>{val.Height}</p>
                                  <h4 className="text-themecolor font-bold uppercase surface:font-semibold">
                                    Bust
                                  </h4>
                                  <p className="m-0">{val.Bust}</p>
                                </div>
                                <div className="mx-auto leading-9">
                                  <h4 className="text-themecolor font-bold uppercase surface:font-semibold">
                                    Weight
                                  </h4>
                                  <p>{val.Weight}</p>
                                  <h4 className="text-themecolor font-bold uppercase surface:font-semibold">
                                    waist
                                  </h4>
                                  <p className="m-0">{val.Waist}</p>
                                </div>
                                <div className="mx-auto leading-9">
                                  <h4 className="text-themecolor font-bold uppercase surface:font-semibold">
                                    Age
                                  </h4>
                                  <p>{val.Age}</p>
                                  <h4 className="text-themecolor font-bold uppercase surface:font-semibold">
                                    Hips
                                  </h4>
                                  <p className="m-0">{val.Hips}</p>
                                </div>
                              </div>
                              <div className="models-social-box">
                                <div>
                                  <Link to={val.Facebook|| "https://facebook.com/"} target="_blank">
                                    <FaFacebookF className="text-2xl m-3 my-4" />
                                  </Link>
                                  <Link to={val.Twitter || "https://x.com/"} target="_blank">
                                    <FaXTwitter className="text-2xl m-3 my-4" />
                                  </Link>
                                  <Linkto={val.Instagram|| "https://instagram.com/"} target="_blank">
                                    <FaInstagram className="text-2xl m-3 my-4" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                ) : (
                  <Slider {...topinfluencer}>
                    {ArtistData?.SimilarVendors?.map((val, index) => (
                      <div key={index} className="p-5">
                        <Link
                          to={`/influencer/${val.Id}`}
                          className="relative models-box cursor-pointer "
                        >
                          <img
                            src={val.ProfileImage}
                            alt=""
                            className="rounded-lg h-[500px] w-full object-cover"
                          />
                          <h2 className="absolute bottom-0 left-0 right-0 bg-themecolor1 p-3 text-center text-white text-2xl font-bold rounded-tl-none rounded-tr-none rounded-lg">
                            {val.Name}
                          </h2>

                          <div className="overlay absolute bottom-0 text-white w-full transition-opacity ease-linear duration-500 opacity-0 text-xl p-0 text-center">
                            <div className="bg-black px-4 py-1 text-left text-xl lg:text-2xl">
                              <p>{val.Name}</p>
                            </div>
                            <div className="flex items-center justify-between bg-black">
                              <div className="box-border flex items-center justify-between w-full bg-white text-black">
                                <div className="mx-auto leading-9">
                                  <h4 className="text-themecolor font-bold uppercase surface:font-semibold">
                                    Height
                                  </h4>
                                  <p>{val.Height}</p>
                                  <h4 className="text-themecolor font-bold uppercase surface:font-semibold">
                                    Bust
                                  </h4>
                                  <p className="m-0">{val.Bust}</p>
                                </div>
                                <div className="mx-auto leading-9">
                                  <h4 className="text-themecolor font-bold uppercase surface:font-semibold">
                                    Weight
                                  </h4>
                                  <p>{val.Weight}</p>
                                  <h4 className="text-themecolor font-bold uppercase surface:font-semibold">
                                    waist
                                  </h4>
                                  <p className="m-0">{val.Waist}</p>
                                </div>
                                <div className="mx-auto leading-9">
                                  <h4 className="text-themecolor font-bold uppercase surface:font-semibold">
                                    Age
                                  </h4>
                                  <p>{val.Age}</p>
                                  <h4 className="text-themecolor font-bold uppercase surface:font-semibold">
                                    Hips
                                  </h4>
                                  <p className="m-0">{val.Hips}</p>
                                </div>
                              </div>
                              <div className="models-social-box">
                                <div>
                                  <Link to={val.Facebook|| "https://facebook.com/"} target="_blank">
                                    <FaFacebookF className="text-2xl m-3 my-4" />
                                  </Link>
                                  <Link to={val.Twitter || "https://x.com/"} target="_blank">
                                    <FaXTwitter className="text-2xl m-3 my-4" />
                                  </Link>
                                  <Linkto={val.Instagram|| "https://instagram.com/"} target="_blank">
                                    <FaInstagram className="text-2xl m-3 my-4" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
            </div>
          */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistDetail;
