import React, { useEffect, useState } from "react";
import DashboardMenu from "../../DashboardMenu";
import ScrollToTop from "../ScrollToTop";
import { useFormik } from "formik";
import FormLoader from "../../../common/FormLoader";
import Config from "../../../API/Config";
import {InfluencerGetById,UpdateModelInfById} from "../../../API/InfluencerSignUpAPI";
import { useData } from "../../../Context/DataContext ";
import { format, parse } from "date-fns";
import usePageSEO from "../../usepageSEO/Index";

const ModelProfile = () => {
    usePageSEO(
        "Viral kar | Dashboard", // Use page title for SEO if available
        "Viral kar | Dashboard", // Use page description for SEO if available
        ["Viral kar | Dashboard"] // No keywords provided in this example
      );
      
      const [ThumbImagePreview, setThumbImagePreview] = useState(); // For handling image preview
      const Id = Config.getId(); // Retrieve Influencer Id
      const Status = Config.getStatus(); // Retrieve Influencer status
      const { CityData, CategoryData } = useData(); // Assuming useData is returning city and category data
      
      // Formik setup
      const formik = useFormik({
        initialValues: {
          Name: "",
          Email: "",
          Phone: "",
          DOB: "",
          Gender: "",
          CityId: "",
          Instagram: "",
          Facebook: "",
          Youtube: "",
          Twitter: "",
          LinkedIn: "",
          TikTok: "",
          Languages: "",
          EngagementRate: "",
          FollowerCount: "",
          TermsandConditions: false,
          PrivacyPolicy: false,
          ProfileImage: "",
          HidImg: "",
          Category: [] // Initialize Category as an empty array
        },
      
        onSubmit: async (values, actions) => {
          setIsFormLoading(true);
          try {
            const formData = new FormData();
      
            // Append normal form fields
            Object.entries(values).forEach(([key, value]) => {
              formData.append(key, value);
            });
      
            // Update influencer data by ID
            const result = await UpdateModelInfById(Id, formData);
      
            // Refetch data after update
            await FatchData();
          } catch (error) {
            console.error("Error updating influencer data:", error);
          } finally {
            setIsFormLoading(false); // End loading state after form submission
          }
        }
      });
      
      // Fetch influencer data by ID and set form fields
      const FatchData = async () => {
        try {
          const result = await InfluencerGetById(Id);
      
          // Handle ProfileImage with full path
          if (result.ProfileImage) {
            setThumbImagePreview(result.Path + result.ProfileImage);
          }
      
          // Parse and format DOB if it exists
          let formattedDate = "";
          if (result.DOB) {
            const parsedDate = parse(result.DOB, "yyyy-MM-dd'T'HH:mm:ss.SSSX", new Date());
            formattedDate = format(parsedDate, "yyyy-MM-dd");
          }
      
          // Set Formik fields based on API response
          formik.setValues({
            Name: result.Name || "",
            Email: result.Email || "",
            Phone: result.Phone || "",
            DOB: formattedDate || "",
            Gender: result.Gender || "",
            CityId: result.CityName || "", // Assuming CityName is equivalent to CityId
            Instagram: result.Instragram || "",
            Facebook: result.Facebook || "",
            Youtube: result.YouTube || "",
            Twitter: result.Twitter || "",
            LinkedIn: result.LinkedIn || "",
            TikTok: result.TikTok || "",
            Languages: result.Languages || "",
            EngagementRate: result.EngagementRate || "",
            FollowerCount: result.FollowerCount || "",
            TermsandConditions: result.TermsandConditions || false,
            PrivacyPolicy: result.PrivacyPolicy || false,
            ProfileImage: result.ProfileImage || "",
            HidImg: result.Hid_Image || "",
            Category: result.Category || [] // Ensure category is initialized from API response
          });
      
          // Update session storage with ProfileImage for use elsewhere if needed
          sessionStorage.setItem("ProfileImage", ThumbImagePreview);
      
        } catch (error) {
          console.error("Error fetching influencer data:", error);
        }
      };
      
      // Fetch data on component mount or when Id changes
      useEffect(() => {
        if (Id) {
          FatchData();
        }
      }, [Id]);
      
      // Loading state for form
      const [isFormLoading, setIsFormLoading] = useState(false);
  return (
    <div className="bg-dark">
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="">
        <ScrollToTop />
        <div className="container mx-auto">
          <div className="md:flex py-5 md:py-14 px-3 md:gap-10 ">
          <div className="md:w-1/3 mb-5 md:mb-0">
          <DashboardMenu />
          </div>
            {/* ===============================Nav Pilss data ====================================*/}
            <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4">
              <div className="bg-dark-light">
                <form onSubmit={formik.handleSubmit}>
                  <input
                    type="hidden"
                    name="HidImg"
                    value={formik.values.HidImg}
                  />
                  <div className="">
                    <div className="rounded-lg bg-dark-light p-5 dark:border border-white">
                      {Status === 0 ? (
                        <div
                          class="bg-orange-100 border-t-2 mb-4 text-sm border-orange-500 rounded-b text-orange-900 px-4 py-2 shadow-md"
                          role="alert"
                        >
                          <div class="flex justify-center">
                            <p class="font-semibold text-sm">
                              Your Profile is Under Review
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          class="bg-green-100 border-t-4 mb-4 text-sm border-green-500 rounded-b text-green-900 px-4 py-2 shadow-md"
                          role="alert"
                        >
                          <div class="flex justify-center">
                            <p class="font-bold">Your Profile is Approved</p>
                          </div>
                        </div>
                      )}
                      <p className="text-center text-xl py-1.5 mb-3 bg-themebgcolor3 text-white dark:text-white rounded-lg">
                        Details
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="my-1">
                          <label className="text-base" for="">
                            Name <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Name}
                            placeholder="Name"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                          {formik.touched.Name && formik.errors.Name ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Name}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Email
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="email"
                            name="Email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Email}
                            disabled
                            placeholder="Email Address"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder  mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                          {formik.touched.Email && formik.errors.Email ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Email}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Phone <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Phone"
                            disabled
                            readOnly
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Phone}
                            placeholder="Phone"
                            className=" px-4 py-3 w-full focus:outline-none  mt-3 placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                          {formik.touched.Phone && formik.errors.Phone ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Phone}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            DOB <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="date"
                            name="DOB"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.DOB}
                            placeholder="DOB"
                            className=" px-4 py-3 w-full focus:outline-none  mt-3 placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                          {formik.touched.DOB && formik.errors.DOB ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.DOB}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Profile Picture{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="file"
                            name="ProfileImage"
                            onChange={(event) => {
                              formik.setFieldValue(
                                "ProfileImage",
                                event.currentTarget.files[0]
                              );
                            }}
                            onBlur={formik.handleBlur}
                            placeholder="Business Country"
                            className="text-sm text-grey-500 file:mr-5  mt-3 file:py-1.5 file:px-5 file:rounded-full file:border-0 file:text-md file:font-semibold  file:text-white
                            file:bg-gradient-to-r file:from-themecolor file:to-black hover:file:cursor-pointer hover:file:opacity-80 py-1.5 w-full focus:outline-none border-0 rounded"
                          />
                          {formik.touched.ProfileImage &&
                          formik.errors.ProfileImage ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.ProfileImage}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Portfolio Images
                            <span className="text-red-500 text-sm">
                              *(Multiple)
                            </span>
                          </label>
                          <div className="relative">
                            <input
                              type="file"
                              name="VendorImages"
                              multiple
                              onChange={(event) => {
                                const files = Array.from(
                                  event.currentTarget.files
                                );
                                formik.setFieldValue("VendorImages", files);
                              }}
                              onBlur={formik.handleBlur}
                              placeholder="Business Country"
                              className="text-sm text-grey-500 file:mr-5 file:py-1.5  mt-3 file:px-5 file:rounded-full file:border-0 file:text-md file:font-semibold  file:text-white
                                file:bg-gradient-to-r file:from-themecolor file:to-black hover:file:cursor-pointer hover:file:opacity-80 py-1.5 w-full focus:outline-none mt-3 border-0 rounded "
                            />
                          </div>
                          {formik.touched.VendorImages &&
                          formik.errors.VendorImages ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.VendorImages}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label className="text-base">
                            Select City <span className="text-red-500">*</span>
                          </label>
                          <select
                            name="CityId"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.CityId}
                            className=" px-4 py-3 w-full focus:outline-none  mt-3 placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          >
                            {CityData?.map((val, index) => {
                              return (
                                <option key={index} value={val.Id}>
                                  {val.Title}
                                </option>
                              );
                            })}
                          </select>
                          {formik.touched.CityId && formik.errors.CityId ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.CityId}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label className="text-base">
                            Gender <span className="text-red-500">*</span>
                          </label>
                          <div className="flex items-center mt-2 dark:bg-[#1e1e1e] px-2 py-2.5">
                            <div className="mr-3">
                              <input
                                type="radio"
                                id="Male"
                                name="Gender"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="M"
                                checked={formik.values.Gender === "M"}
                                className="mr-2"
                              />
                              <label htmlFor="Male">Male</label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id="Female"
                                name="Gender"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="F"
                                checked={formik.values.Gender === "F"}
                                className="mr-3"
                              />
                              <label htmlFor="Female">Female</label>
                            </div>
                          </div>

                          {formik.touched.Gender && formik.errors.Gender ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Gender}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label className="text-base">
                            Type <span className="text-red-500">*</span>
                          </label>
                          <div className="flex items-center mt-2 dark:bg-[#1e1e1e] px-2 py-2.5">
                            <div className="mr-3">
                              <input
                                type="radio"
                                id="Model"
                                name="Type"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="M"
                                checked={formik.values.Type === "M"}
                                className="mr-2"
                              />
                              <label htmlFor="Model">Model</label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id="Influencer"
                                name="Type"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="I"
                                className="mr-3"
                                checked={formik.values.Type === "I"}
                              />
                              <label htmlFor="Influencer">Influencer </label>
                            </div>
                          </div>

                          {formik.touched.Type && formik.errors.Type ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Type}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          {formik.values.Type === "I" ? (
                            <div className="my-1">
                              <label for="">
                                Composite Card{" "}
                                <span className="text-red-500 text-sm">
                                  *(Atleast 5 Image Upload)
                                </span>
                              </label>
                              <div className="relative">
                                <input
                                  type="file"
                                  name="CompositeCardImages"
                                  multiple
                                  onChange={(event) => {
                                    const files = Array.from(
                                      event.currentTarget.files
                                    );
                                    formik.setFieldValue(
                                      "CompositeCardImages",
                                      files
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  placeholder="Business Country"
                                  className="dark:bg-[#1e1e1e] p-4 py-2 w-full focus:outline-none my-1.5 border border-zinc-600 "
                                />
                              </div>
                              {formik.touched.CompositeCardImages &&
                              formik.errors.CompositeCardImages ? (
                                <div className="text-red-500 text-sm">
                                  {formik.errors.CompositeCardImages}
                                </div>
                              ) : null}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <p className="text-center text-xl py-1.5 mb-3 bg-themebgcolor3 text-white dark:text-white rounded-lg">
                        Other Details
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-4">
                        {CategoryData?.[0]?.ChildCat?.map((val, index) => (
                          <div className="flex items-center my-2" key={index}>
                            <input
                              type="checkbox"
                              className="mr-2"
                              name="Category"
                              value={val.Id} // Ensure this matches the type of data in Formik
                              checked={formik.values.Category.includes(val.Id.toString())} // Convert ID to string
                              id={val.Title}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  // Add category to the array
                                  formik.setFieldValue(
                                    "Category",
                                    [...formik.values.Category, val.Id.toString()]
                                  );
                                } else {
                                  // Remove category from the array
                                  formik.setFieldValue(
                                    "Category",
                                    formik.values.Category.filter(
                                      (category) => category !== val.Id.toString()
                                    )
                                  );
                                }
                              }}
                            />
                            <label
                              className="text-sm text-gray-500 font-normal"
                              htmlFor={val.Title}
                            >
                              {val.Title}
                            </label>
                          </div>
                        ))}
                      </div>
                      <p className="text-center text-xl py-1.5 mb-3 bg-themebgcolor3 text-white dark:text-white rounded-lg mt-4">
                        Social Media Details
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div className="my-1">
                          <label for="">
                            Instagram <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Instagram"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Instagram}
                            placeholder="Instagram"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Instagram &&
                          formik.errors.Instagram ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Instagram}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Facebook <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Facebook"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Facebook}
                            placeholder="Facebook"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Facebook && formik.errors.Facebook ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Facebook}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Youtube <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Youtube"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Youtube}
                            placeholder="Youtube"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Youtube && formik.errors.Youtube ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Youtube}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Twitter <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Twitter"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Twitter}
                            placeholder="Twitter"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder  border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Twitter && formik.errors.Twitter ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Twitter}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">Blog</label>
                          <input
                            type="text"
                            name="Blog"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Blog}
                            placeholder="Blog"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Blog && formik.errors.Blog ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Blog}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">Roposo</label>
                          <input
                            type="text"
                            name="Roposo"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Roposo}
                            placeholder="Roposo"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Roposo && formik.errors.Roposo ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Roposo}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">MXTakatak</label>
                          <input
                            type="text"
                            name="MXTakatak"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.MXTakatak}
                            placeholder="MXTakatak"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.MXTakatak &&
                          formik.errors.MXTakatak ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.MXTakatak}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="flex justify-center my-3">
                        <button
                          type="submit"
                          className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-10"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModelProfile