import React from "react";
import Breadcrumb from "../../Breadcumb/Breadcumb";
import BlogBanner from "../../Images/BlogBanner.jpg";
import BlogImg1 from "../../Images/blogImg1.jpg";
import BlogImg2 from "../../Images/blogImg2.jpg";
import BlogImg3 from "../../Images/blogImg3.jpg";
import BlogImg4 from "../../Images/blogImg4.jpg";
import RecentPostImg1 from "../../Images/RecentPostImg1.jpg";
import RecentPostImg2 from "../../Images/RecentPostImg2.jpg";
import RecentPostImg3 from "../../Images/RecentPostImg3.jpg";
import RecentPostImg4 from "../../Images/RecentPostImg4.jpg";
import { Link } from "react-router-dom";

const Blog = () => {
  const breadcrumbItems = [
    {
      text: "Blog",
    },
  ];

  const blogdata = [
    {
      image: BlogImg1,
      title: "  How To Become A Model",
      date: " April 16, 2019 Lifestyle by Joan Clare",
      des: " The world of modeling has something for everyone, the good news is that with the rise of the Internet and a trend towards more diversity in casting, There are so many niches and specializations that you can find at least one that fits your look and personality wide range of people who may want to give modelling a go. The first thing every aspiring model needs to know is what their best features are and how to flaunt them. there are lots of opportunities for people of all different shapes, sizes ...",
    },
    {
      image: BlogImg2,
      title: "How Do Models Get Hired? Guide",
      date: " April 16, 2019 Lifestyle by Joan Clare",
      des: " The modeling industry can be as glamorous as it is competitive, and for aspiring models, understanding how to get hired is crucial to building a successful career. Whether you're a new face eager to break into the industry or an experienced model looking to secure more gigs, knowing how the hiring process works can give you a significant advantage. ",
    },
    {
      image: BlogImg3,
      title: "How to Hire a Model: Finding Talent Easily and Affordably",
      date: " April 16, 2019 Lifestyle by Joan Clare",
      des: " In today’s visually-driven world, hiring the right model can make or break your brand’s success. Whether you’re organizing a photoshoot, planning a fashion show, or looking to enhance your marketing materials, the quality and presence of your models can have a significant impact on your project’s outcome.",
    },
    {
      image: BlogImg4,
      title: "Promotional Models",
      date: " April 16, 2019 Lifestyle by Joan Clare",
      des: " A promotional model is a model that directly interacts with potential customers on hire to meet consumer demand for a product, service, brand, or concept. Sometimes as brand ambassadors, these models are hired to promote a particular brand or product.",
    },
  ];

  const recentpost = [
    {
      image: RecentPostImg1,
      title: "  New York Fashion Story",
      date: " April 16, 2019 Lifestyle by Joan Clare",
    },
    {
      image: RecentPostImg2,
      title: "  New York Fashion Story",
      date: " April 16, 2019 Lifestyle by Joan Clare",
    },
    {
      image: RecentPostImg3,
      title: "  New York Fashion Story",
      date: " April 16, 2019 Lifestyle by Joan Clare",
    },
    {
      image: RecentPostImg4,
      title: "  New York Fashion Story",
      date: " April 16, 2019 Lifestyle by Joan Clare",
    },
  ];

  return (
    <div className="dark:bg-darkmode dark:text-white">
      <Breadcrumb items={breadcrumbItems} backgroundImage={BlogBanner} />
      <div className="container mx-auto pt-14 pb-10 px-3">
        <div className="md:flex gap-10">
          <div className="md:w-3/4">
            <div className="grid md:grid-cols-2 gap-8">
              {blogdata.map((val, index) => (
                <div className="rounded-xl shadow-[0_0px_15px_-6px_rgba(0,0,0,0.3)] hover:translate-y-[-5px] transition ease duration-300 dark:border">
                  <div className="mb-10" key={index}>
                    <Link to={"/blog-details"}>
                      <div className="overflow-hidden relative md:h-[18rem] rounded-md mb-4">
                        <img
                          src={val.image}
                          alt=""
                          className="h-full w-full object-cover"
                        />
                      </div>
                      <h1 className="py-3 text-lg md:text-2xl text-center font-semibold">
                        {val.title}
                      </h1>
                    </Link>
                    <div className="border-t-4 border-themecolor w-1/4 flex mx-auto"></div>

                    <p className="text-center text-sm text-gray-400 py-5">{val.date}</p>

                    <p className="text-center px-4 text-sm text-gray-600 line-clamp-5">{val.des} </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="md:md:w-1/4">
            {/* <div className="bg-zinc-100 p-8 text-zinc-700">
              <h1 className="text-center text-2xl font-semibold uppercase  pb-2">
                Categories
              </h1>
              <div className="border-t-4 border-themecolor w-1/4 flex mx-auto"></div>
              <div className="flex mt-5 hover:border-l-2 border-themecolor hover:text-themecolor ps-2 duration-300">
                <h1 className="text-lg font-semibold uppercase">Blog</h1>
                <h1 className="text-lg font-semibold uppercase ml-auto">71</h1>
              </div>
              <div className="flex mt-5 hover:border-l-2 border-themecolor hover:text-themecolor ps-2 duration-300">
                <h1 className="text-lg font-semibold uppercase">Events</h1>
                <h1 className="text-lg font-semibold uppercase ml-auto">20</h1>
              </div>
              <div className="flex mt-5 hover:border-l-2 border-themecolor hover:text-themecolor ps-2 duration-300">
                <h1 className="text-lg font-semibold uppercase">
                  Lastest News
                </h1>
                <h1 className="text-lg font-semibold uppercase ml-auto">13</h1>
              </div>
              <div className="flex mt-5 hover:border-l-2 border-themecolor hover:text-themecolor ps-2 duration-300">
                <h1 className="text-lg font-semibold uppercase">
                  Uncategorised
                </h1>
                <h1 className="text-lg font-semibold uppercase ml-auto">29</h1>
              </div>
              <div className="flex mt-5 hover:border-l-2 border-themecolor hover:text-themecolor ps-2 duration-300">
                <h1 className="text-lg font-semibold uppercase">Model</h1>
                <h1 className="text-lg font-semibold uppercase ml-auto">9</h1>
              </div>
            </div> */}

            <div className="mx-auto sticky lg:top-32">
              <div className="rounded-xl shadow-[0_0px_15px_-6px_rgba(0,0,0,0.3)] hover:translate-y-[-5px] transition ease duration-300 dark:border">
                <h1 className="text-center text-white text-lg font-semibold uppercase py-2 bg-themecolor1 rounded-t-lg">
                  Recent Posts
                </h1>
                <div className="py-4">
                  {recentpost.map((val, index) => (
                    <Link to={"/blog-details"}>
                      <div className="mb-3 flex px-3">
                        <div className="overflow-hidden  relative h-20 w-40 rounded me-5">
                          <img
                            src={val.image}
                            alt=""
                            className="h-full w-full object-cover object-top"
                          />
                        </div>
                        <div className="w-5/6">
                          <h1 className="text-sm font-semibold">
                            {val.title}
                          </h1>

                          <p className="text-gray-400 text-[12px] pt-1">
                            {val.date}
                          </p>
                        </div>
                      </div>
                    </Link>

                  ))}
                </div>
              </div>
            </div>
            {/* <div className="overflow-hidden relative h-80 w-80 mx-auto">
              <img
                src={BlogMain}
                alt=""
                className="h-full w-full object-cover rounded-full"
              />
            </div>
            <h1>Estelle</h1> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
