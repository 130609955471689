import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Config from "../API/Config";
import userImage from "../Images/usericon.png";
import Swal from "sweetalert2"; // Import SweetAlert2

const DashboardMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // Clear session storage and navigate to the logout page
        sessionStorage.clear();
        navigate("/");
        window.location.reload();
      }
    });
  };

  const [userType, setUserType] = useState("");

  useEffect(() => {
    const type = Config.getType();
    setUserType(type);
  }, []);

  const menuItems = [
    { path: "/profileview", label: "Profile" },
    // { path: "/campaigns", label: "Campaigns" },

    // { path: "/profile/model", label: "Profile" },
    // { path: "/enquiry", label: "Enquiry" },
    // { path: "/campaign", label: "Campaign" },
    // Other menu items
  ];

  const isActive = (path) => {
    return location.pathname === path;
  };

  const ProfileImage = sessionStorage.getItem("ProfileImage");

  return (
    <div className="rounded-lg bg-dark-light p-5 dark:border border-white">
      <ul
        className="flex mb-5 list-none flex-col"
        role="tablist"
      >
        <img
          src={ProfileImage === "undefined" ? userImage : ProfileImage}
          alt=""
          className="rounded-full h-32 w-32 mb-5 shadow-2xl p-2 mx-auto border"
        />

        {menuItems.map(
          (item, index) =>
            ((index < 2) ||
              (index >= 2)) && (
              <li
                key={index}
                className={`last:mr-0 text-center border dark:border-zinc-600 my-1.5 ${
                  isActive(item.path) ? "bg-themecolor1 text-white" : ""
                }`}
                onClick={() => navigate(item.path)}
              >
                <Link
                  to={item.path}
                  className={`text-xs font-bold uppercase px-0 py-4 border-none block leading-normal ${
                    isActive(item.path) ? "bg-themecolor1 text-white" : ""
                  }`}
                >
                  {item.label}
                </Link>
              </li>
            )
        )}
        <li className="last:mr-0 my-1.5 text-center border dark:border-zinc-600 cursor-pointer">
          <div
            onClick={logout}
            className="text-xs font-bold uppercase px-0 py-4 border-none block leading-normal"
          >
            Logout
          </div>
        </li>
      </ul>
    </div>
  );
};

export default DashboardMenu;
