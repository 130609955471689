import React, { useEffect, useState } from "react";
import HomeBackImg from "../../Images/home-page-back.png";
import titleimage from "../../Images/title-icon.png";
import { Link, useLocation, useParams } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { getAllInfluencer, getInfluencerByCity } from "../../API/ModelInfMain";
import NoDataFound from "../../common/NodataFound";
import ClipLoader from "react-spinners/FadeLoader";
import usePageSEO from "../usepageSEO/Index";

const CityInfluencer = () => {
  const { Slug } = useParams();
  const location = useLocation();
  const id = location.state?.id; // Get Id from state

  const [InfluencerData, setInfluencerData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const getData = async () => {
    try {
      const results = await getInfluencerByCity(id);
      setInfluencerData(results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [id]);

  usePageSEO(
    "Viral kar", // Use page title for SEO if available
    "Viral kar", // Use page description for SEO if available
    ["Viral kar"] // No keywords provided in this example
  );

  return (
    <div className="">
      {/*-------------- male - Influencers -----------------*/}

      <div className="container mx-auto xl:py-20 py-10 px-2 xl:px-0 lg:px-4">
        <p className="page-title text-center dark:text-white capitalize pb-3">{Slug} Influencers</p>
        <img src={titleimage} alt="" className="mx-auto title-icon" />
      </div>
      <div className="">
        <div
          className="subscribe-bg lg:mb-0 relative bg-cover bg-center bg-no-repeat py-20 px-5 md:px-9 xl:px-0 "
          style={{ backgroundImage: `url(${HomeBackImg})` }}
        >
          {loading ? (
            <div className="flex justify-center items-center my-40">
              <ClipLoader color={"#d00067"} loading={loading} size={40} />
            </div>
          ) : (
            <div className="container mx-auto relative">
              {InfluencerData?.length === 0 ? (
                <NoDataFound />
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 container mx-auto gap-7">
                  {InfluencerData?.map((val, index) => {
                    return (
                      <Link to={`/influencer/${val.Id}`}>
                        <div class="item pb-[146px] group relative grid grid-cols-1 ">
                          <div class="relative overflow-hidden surface:h-[20rem] xl:h-[25rem] h-60">
                            <img
                              class="block w-full transform-style-[preserve-3d] h-full object-cover object-top rounded-t-lg"
                              src={val.ProfileImage}
                              alt="#"
                            />
                          </div>
                          <div class="  border-l-3 border-[#95c6d8] shadow-md rounded-b-lg bottom-0 left-0 overflow-hidden perspective-[500px] min-h-[146px] absolute right-0 px-[20px]   pt-[20px] transition-all duration-500 ease-in-out bg-white dark:bg-darkmode dark:text-white z-20 group-hover:bg-[#f5f5f5] dark:group-hover:bg-darkmode group-hover:border-l-transparent group-hover:pt-[37px] group-hover:-translate-y-4 ">
                            <h3 class=" text-xl text-left font-semibold leading-[1] tracking-normal mb-[18px] transition-transform duration-500 ease-in-out transform group-hover:-translate-y-2">
                              <Link to={`/influencer/${val.Id}`}>
                                {val.Name}
                              </Link>
                            </h3>
                            <h3 class="text-md text-left leading-[1]   tracking-normal my-[18px] transition-transform duration-500 ease-in-out transform group-hover:-translate-y-2">
                              {val.Name}
                            </h3>

                            <div class="hidden group-hover:block transition-all duration-500 ease-in-out transform group-hover:-translate-y-4 group-hover:opacity-100 border-l-4 border-themecolor">
                              <div class="flex flex-col space-y-[10px] opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out transform group-hover:translate-y-0 translate-y-[50px]">
                                <div className="grid grid-cols-2 lg:grid-cols-4">
                                  <div class="inline-block text-left leading-[1.4] mx-[8px]">
                                    <span class="font-semibold block overflow-hidden mb-[3px] text-xs lg:text-base">
                                      Height
                                    </span>
                                    {val.Height}
                                  </div>
                                  <div class="inline-block text-left leading-[1.4] mx-[8px]">
                                    <span class="font-semibold block overflow-hidden mb-[3px] text-xs lg:text-base">
                                      Weight
                                    </span>
                                    {val.Weight}
                                  </div>
                                  <div class="inline-block text-left leading-[1.4] mx-[8px]">
                                    <span class="font-semibold block overflow-hidden mb-[3px] text-xs lg:text-base">
                                      Waist
                                    </span>
                                    {val.Waist}
                                  </div>
                                  <div class="inline-block text-left leading-[1.4] mx-[8px]">
                                    <span class="font-semibold block overflow-hidden mb-[3px] text-xs lg:text-base">
                                      Hips
                                    </span>
                                    {val.Hips}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="absolute bottom-0 right-0 top-0 w-[40px] bg-black opacity-0 transition-all duration-500 ease-in-out transform translate-y-[50px] group-hover:translate-y-0 group-hover:opacity-100 flex flex-col justify-center space-y-4 text-white text-xl text-center">
                              <div class="social-icon ">
                                <Link
                                  to={val.Facebook || "https://facebook.com/"}
                                  target="_blank"
                                  className="text-center"
                                >
                                  <span>
                                    <FaFacebookF className="mx-auto" />
                                  </span>
                                </Link>
                              </div>
                              <div class="social-icon">
                                <Link
                                  to={val.Twitter || "https://x.com/"}
                                  target="_blank"
                                  className="text-center"
                                >
                                  <span>
                                    <FaXTwitter className="mx-auto" />
                                  </span>
                                </Link>
                              </div>
                              <div class="social-icon">
                                <Link
                                  to={val.Youtube || "https://youtube.com/"}
                                  target="_blank"
                                  className="text-center"
                                >
                                  <span>
                                    <FaYoutube className="mx-auto" />
                                  </span>
                                </Link>
                              </div>
                              <div class="social-icon">
                                <Link
                                  to={val.Instagram || "https://instagram.com/"}
                                  target="_blank"
                                  className="text-center"
                                >
                                  <span>
                                    <FaInstagram className="mx-auto" />
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CityInfluencer;
