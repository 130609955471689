import React from "react";
import Breadcrumb from "../../Breadcumb/Breadcumb";
import AboutUdBanner from "../../Images/about-banner.jpg";
import titleimage from "../../Images/title-icon.png";
import AboutUsImg1 from "../../Images/AboutUsImg1.jpg";
import AboutUsImg2 from "../../Images/mi-7.jpg";
import AboutUsImg3 from "../../Images/AboutUsImg2.jpg";
import AboutUsImg4 from "../../Images/MM-IN-07.jpg";
import { GoTrophy } from "react-icons/go";
import {
  IoBagHandleOutline,
  IoCameraOutline,
  IoMegaphoneOutline,
} from "react-icons/io5";
import TeamImg1 from "../../Images/TeamImg1.jpg";
import TeamImg2 from "../../Images/TeamImg2.jpg";
import TeamImg3 from "../../Images/TeamImg3.jpg";
import TeamImg4 from "../../Images/TeamImg4.jpg";

const AboutUs = () => {
  const breadcrumbItems = [
    {
      text: "About Us",
    },
  ];

  const backgroundImage = AboutUdBanner;
  return (
    <div className="dark:bg-darkmode dark:text-white">
      <Breadcrumb items={breadcrumbItems} backgroundImage={backgroundImage} />

      <div className="">
        <div className="container mx-auto pt-10 px-5 md:px-0">
          <div className="md:flex items-center gap-10">
            <div className="md:w-3/5">
              <h2 className="text-4xl mb-5 text-themecolor1">About Us</h2>
              <h3 className="font-medium text-textcolor2">Welcome to ViralKar.com!</h3>
              <p className="py-3">
                At ViralKar.com, we are redefining the way influencers, models,
                and artists connect with opportunities and showcase their talent.
                Our platform serves as a vibrant marketplace where creativity
                meets opportunity, empowering individuals to shine in their
                respective fields and achieve their professional goals.
              </p>
              <p className="py-3">
                talent.
                Our platform serves as a vibrant marketplace where creativity
                meets opportunity, empowering individuals to shine in their
                respective fields and achieve their professional goals.
              </p>
            </div>
            <div className="mt-5 md:w-2/5 lg:mt-0 ">
              <div className="overflow-hidden relative h-[30rem]">
                <img
                  src={AboutUsImg4}
                  alt=""
                  className="h-full w-full object-cover object-top"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 py-5 md:py-16 mt-10">
        <div className="container mx-auto px-5 md:px-0">
          <div className="md:flex items-center gap-10">
            <div className="mt-5 md:w-2/5 lg:mt-0  mb-5 md:mb-0">
              <div className="overflow-hidden relative h-[30rem]">
                <img
                  src={AboutUsImg1}
                  alt=""
                  className="h-full w-full object-cover object-top"
                />
              </div>
            </div>
            <div className="md:w-3/5">
              <h2 className="md:text-4xl text-2xl md:mb-5 text-themecolor1">Our Mission</h2>
              <p className="py-3">Our mission is to build a dynamic community that bridges the gap between talented creators and the brands, agencies, and projects seeking their unique skills. We aim to provide a platform where every influencer, model, and artist can find opportunities to grow their career, connect with like-minded professionals, and make their mark on the global stage.</p>

              <h6 className="font-semibold text-xl">What We Offer</h6>
              <ul className="mt-5">
                <li className="pb-4">
                  <span className="font-bold">• For Influencers: </span>
                  ViralKar.com offers a space to connect with top brands and
                  collaborate on exciting campaigns. Showcase your unique style,
                  engage with your audience, and explore new opportunities to
                  elevate your influence.
                </li>
                <li className="pb-4">
                  <span className="font-bold">• For Models: </span>
                  Discover a wealth of modeling opportunities, from fashion shows
                  to brand endorsements. Build your portfolio, connect with
                  industry professionals, and step into the spotlight with
                  confidence.
                </li>
                <li className="pb-4">
                  <span className="font-bold">• For Influencers: </span>
                  Whether you’re a visual artist, musician, or performer,
                  ViralKar.com provides a platform to display your work,
                  collaborate on creative projects, and gain recognition in the
                  art community.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>



      <div className="">
        <div className="container mx-auto pt-10 px-5 md:px-0">
          <div className="md:flex items-center gap-10">
            <div className="md:w-3/5">
              <h2 className="md:text-4xl text-2xl md:mb-5 text-themecolor1">Values</h2>
              <ul className="mt-5">
                <li className="pb-4">
                  <span className="font-bold">• Creativity: </span>
                  We celebrate creativity and encourage our users to showcase
                  their unique talents and perspectives. Our platform is
                  designed to foster innovation and artistic expression.
                </li>
                <li className="pb-4">
                  <span className="font-bold">• Inclusivity: </span>
                  We are committed to creating an inclusive environment where
                  individuals from all backgrounds and experiences are welcome.
                  Everyone deserves a chance to be seen and heard.
                </li>
                <li className="pb-4">
                  <span className="font-bold">• Integrity: </span>
                  We uphold the highest standards of integrity and
                  professionalism. Our goal is to provide a trustworthy platform
                  that respects the rights and interests of all users.
                </li>
                <li className="pb-4">
                  <span className="font-bold">• Empowerment: </span>
                  We believe in empowering our users to take control of their
                  careers and achieve their dreams. By providing valuable tools
                  and opportunities, we help you unlock your full potential.
                </li>
              </ul>
            </div>
            <div className="mt-5 md:w-2/5 lg:mt-0 ">
              <div className="overflow-hidden relative h-[30rem]">
                <img
                  src={AboutUsImg2}
                  alt=""
                  className="h-full w-full object-cover object-top"
                />
              </div>
            </div>
          </div>
        </div>
      </div>


    <div className="bg-gray-100 md:py-16 py-5 mt-10">
      <div className="container mx-auto px-5 md:px-0">
      <div className="mt-0">
          <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-10 ">
            
          <div className="mt-5 lg:mt-0 mb-5 md:mb-0">
              <div className="overflow-hidden relative h-[30rem]">
                <img
                  src={AboutUsImg3}
                  alt=""
                  className="h-full w-full object-cover object-top"
                />
              </div>
            </div>

            <div className="col-span-2">

              <h2 className="md:text-4xl text-2xl mb-5 text-themebgcolor3">Our services</h2>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-5">
                <div className="">
                  <div className="flex space-x-4">
                    <h1 className="pt-1 text-5xl text-themecolor">
                      <GoTrophy />
                    </h1>
                    <div className="grid">
                      <h6 className="text-lg font-semibold uppercase ">
                        Influencer Collaboration
                      </h6>
                      <p className="text-zinc-500 pt-2">
                        Quisque vel tincidunt nisi. Nam vitae lorem vel erat
                        laoreet egestas eu non enim. Nulla vitae facilisis ex.
                        In a maximus ligula nunc.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="flex space-x-4">
                    <h1 className="pt-1 text-5xl text-themecolor">
                      <IoMegaphoneOutline />
                    </h1>
                    <div className="grid">
                      <h6 className="text-lg font-semibold uppercase ">
                        Model Opportunities
                      </h6>
                      <p className="text-zinc-500 pt-2">
                        Vivamus gravida imperdiet tellus, consequat tincidunt
                        dolor lobortis sed. Morbi sed sapien accumsan,
                        pellentesque mi sed vehicula.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="flex space-x-4">
                    <h1 className="pt-1 text-5xl text-themecolor">
                      <IoCameraOutline />
                    </h1>
                    <div className="grid">
                      <h6 className="text-lg font-semibold uppercase ">
                        Artistic Projects
                      </h6>
                      <p className="text-zinc-500 pt-2">
                        Pellentesque aliquet sodales libero sed interd. Sed enim
                        ligula, ornare ac varius non, volutpat sit amet tellus.
                        Nulla vitae urna.
                      </p>
                    </div>
                  </div>
                </div>
           
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>




      <div className="container mx-auto py-10 px-5 md:px-0">     

        <div className="">
          <div className="col-span-2">
            <div className="flex items-center justify-center flex-col">
              <p className="page-title text-center">Professional Team</p>
              <img src={titleimage} className="text-center title-icon" alt="" />
            </div>
            <p className="py-3 text-center">
              Our team is passionate about supporting the creative community and
              making a positive impact in the industry. With diverse expertise
              in digital marketing, technology, and creative arts, we are
              dedicated to providing the best possible experience for our users.
            </p>
          </div>
        </div>

        <div className="mt-20">
          <div className="grid grid-cols-1 lg:grid-cols-4 mt-10">
            <div className="">
              <div className="overflow-hidden relative h-52 w-52 rounded-full mx-auto">
                <img
                  src={TeamImg1}
                  alt=""
                  className="h-full w-full object-cover object-top"
                />
              </div>
              <p className="text-sm text-zinc-500 text-center pt-4">
                Co-Founder
              </p>
              <h1 className="text-lg font-medium text-center">Amy Adams</h1>
            </div>
            <div className="">
              <div className="overflow-hidden relative h-52 w-52 rounded-full mx-auto">
                <img
                  src={TeamImg2}
                  alt=""
                  className="h-full w-full object-cover object-top"
                />
              </div>
              <p className="text-sm text-zinc-500 text-center pt-4">Director</p>
              <h1 className="text-lg font-medium text-center">Dylan Bohn</h1>
            </div>
            <div className="">
              <div className="overflow-hidden relative h-52 w-52 rounded-full mx-auto">
                <img
                  src={TeamImg3}
                  alt=""
                  className="h-full w-full object-cover object-top"
                />
              </div>
              <p className="text-sm text-zinc-500 text-center pt-4">Manager</p>
              <h1 className="text-lg font-medium text-center">
                Lorraine Turner
              </h1>
            </div>
            <div className="">
              <div className="overflow-hidden relative h-52 w-52 rounded-full mx-auto">
                <img
                  src={TeamImg4}
                  alt=""
                  className="h-full w-full object-cover object-top"
                />
              </div>
              <p className="text-sm text-zinc-500 text-center pt-4">Founder</p>
              <h1 className="text-lg font-medium text-center">
                Julia Jameson
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
