import axios from "axios";
import Config from "./Config";
import { toast } from "react-toastify";
const { API_BASE_URL } = Config;

export const ArtistStatusCheck = async (data, navigate) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/artist-status`, data);

    const responseData = response.data;

    if (responseData.responseData && response.data.status === true) {
      const { Id, Email, ArtistName, Phone, Step1, Step2, Step3 } =
        responseData.responseData;

      sessionStorage.setItem("ArtistIdForTemp", JSON.stringify(Id));
      sessionStorage.setItem("ArtistEmailForTemp", JSON.stringify(Email));
      sessionStorage.setItem(
        "ArtistUserNameForTemp",
        JSON.stringify(ArtistName)
      );
      sessionStorage.setItem("ArtistPhoneForTemp", JSON.stringify(Phone));
      sessionStorage.setItem("ArtistStep1ForTemp", JSON.stringify(Step1));
      sessionStorage.setItem("ArtistStep2ForTemp", JSON.stringify(Step2));
      sessionStorage.setItem("ArtistStep3ForTemp", JSON.stringify(Step3));

      if (Step1 === 0 && Step2 === 0 && Step3 === 0) {
        navigate("/artist/sign-up/artist");
        return responseData; // Exit the function
      } else if (Step1 === 1 && Step2 === 0 && Step3 === 0) {
        navigate("/artist/sign-up/artist2");
        return responseData; // Exit the function
      } else if (Step1 === 1 && Step2 === 1 && Step3 === 0) {
        navigate("/artist/sign-up/artist3");
        return responseData; // Exit the function
      } else {
        toast.success(
          "Success! Your email/phone is verified. Let’s continue crafting your artistic journey"
        );
        navigate("/artist-password");
        return responseData; // Exit the function
      }
    }

    if (responseData.InsertId && response.data.status === true) {
      const InsertId = responseData.InsertId;

      if (InsertId) {
        sessionStorage.setItem("ArtistIdForTemp", JSON.stringify(InsertId));
        navigate("/artist/sign-up/artist");
        toast.success(
          "Registration Successful! Complete your profile to showcase your talent as an artist"
        );
      }
    }
  } catch (error) {
    navigate("/artist/sign-up/artist");
    throw error; // Re-throw the error after handling
  }
};

export const GoogleLoginAtristAPI = async (googleData, navigate) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/artist-google-login`,
      googleData
    );

    const responseData = response.data;

    if (responseData.responseData && response.data.status === true) {
      const { Id, Email, ArtistName, Phone, Step1, Step2, Step3 } =
        responseData.responseData;

      sessionStorage.setItem("ArtistIdForTemp", JSON.stringify(Id));
      sessionStorage.setItem("ArtistEmailForTemp", JSON.stringify(Email));
      sessionStorage.setItem(
        "ArtistUserNameForTemp",
        JSON.stringify(ArtistName)
      );
      sessionStorage.setItem("ArtistPhoneForTemp", JSON.stringify(Phone));
      sessionStorage.setItem("ArtistStep1ForTemp", JSON.stringify(Step1));
      sessionStorage.setItem("ArtistStep2ForTemp", JSON.stringify(Step2));
      sessionStorage.setItem("ArtistStep3ForTemp", JSON.stringify(Step3));

      if (Step1 === 0 && Step2 === 0 && Step3 === 0) {
        navigate("/artist/sign-up/artist");
        return responseData; // Exit the function
      } else if (Step1 === 1 && Step2 === 0 && Step3 === 0) {
        navigate("/artist/sign-up/artist2");
        return responseData; // Exit the function
      } else if (Step1 === 1 && Step2 === 1 && Step3 === 0) {
        navigate("/artist/sign-up/artist3");
        return responseData; // Exit the function
      } else {
        toast.success(
          "Success! Your email/phone is verified. Let’s continue crafting your artistic journey"
        );
        navigate("/artist-password");
        return responseData; // Exit the function
      }
    }

    if (responseData.InsertId && response.data.status === true) {
      const InsertId = responseData.InsertId;

      if (InsertId) {
        sessionStorage.setItem("ArtistIdForTemp", JSON.stringify(InsertId));
        navigate("/artist/sign-up/artist");
        toast.success(
          "Registration Successful! Complete your profile to showcase your talent as an artist"
        );
      }
    }
  } catch (error) {
    navigate("/artist/sign-up/artist");
    throw error; // Re-throw the error after handling
  }
};

export const ArtistPassword = async (data, navigate) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/artist-verify`, data);

    const responseData = response.data;

    if (response.data.status === true) {
      sessionStorage.setItem(
        "ArtistId",
        JSON.stringify(responseData.responseData.ArtistId)
      );
      sessionStorage.setItem(
        "ArtistEmail",
        JSON.stringify(responseData.responseData.ArtistEmail)
      );
      sessionStorage.setItem(
        "ArtistPhone",
        JSON.stringify(responseData.responseData.ArtistPhone)
      );
      sessionStorage.setItem(
        "ArtistUserName",
        JSON.stringify(responseData.responseData.ArtistUserName)
      );
      sessionStorage.setItem(
        "ArtistType",
        JSON.stringify(responseData.responseData.Type)
      );
      sessionStorage.setItem(
        "ArtistStatus",
        JSON.stringify(responseData.responseData.Status)
      );

      //   -----------------------------remove----------------------------
      sessionStorage.removeItem("ArtistIdForTemp");
      sessionStorage.removeItem("ArtistEmailForTemp");
      sessionStorage.removeItem("ArtistUserNameForTemp");
      sessionStorage.removeItem("ArtistPhoneForTemp");
      sessionStorage.removeItem("ArtistStep1ForTemp");
      sessionStorage.removeItem("ArtistStep2ForTemp");
      sessionStorage.removeItem("ArtistStep3ForTemp");

      toast.success(
        "Welcome back, artist! You’re logged in and ready to create your next masterpiece."
      );
    } else {
      toast.success(
        "Welcome back, artist! You’re logged in and ready to create your next masterpiece."
      );
    }

    return responseData;
  } catch (error) {
    toast.success(
      "Welcome back, artist! You’re logged in and ready to create your next masterpiece."
    );
    throw error;
  }
};

export const ArtistGetById = async (Id) => {
  try {
    const responce = await axios.get(`${API_BASE_URL}/artist/${Id}`);

    if (responce.data.status === true) {
      return responce.data.responseData[0];
    } else {
      throw new Error(responce.data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const ArtistStep1Add = async (data) => {
  try {
    const responce = await axios.post(`${API_BASE_URL}/artist-step-1`, data);

    if (responce.data.status === true) {
      toast.success("Step 1 Complete! Your creative journey begins now!");
      return responce.data;
    } else {
      toast.error(
        "Oops! Something went wrong while starting your artist profile. Please check your details and try again."
      );
    }
  } catch (error) {
    throw error;
  }
};

export const ArtistStep2Add = async (data) => {
  try {
    const responce = await axios.post(`${API_BASE_URL}/artist-step-2`, data);

    if (responce.data.status === true) {
      toast.success(
        "Step 2 Done! Your artistic profile is almost complete-just one more step!"
      );
      return responce.data;
    } else {
      toast.error(
        "We hit a bump! Try submitting your profile details again, and we’ll get things back on track."
      );
    }
  } catch (error) {
    throw error;
  }
};

export const ArtistStep3Add = async (data) => {
  try {
    const responce = await axios.post(`${API_BASE_URL}/artist-step-3`, data);

    if (responce.data.status === true) {
      toast.success(
        "Congratulations! Your artist profile is live-time to showcase your talent!"
      );
      return responce.data;
    } else {
      toast.error(
        "Technical hiccup! No worries, retry now and get your artistic profile back on the canvas."
      );
    }
  } catch (error) {
    throw error;
  }
};


