import React from "react";
import Breadcrumb from "../../Breadcumb/Breadcumb";
import BlogBanner from "../../Images/BlogBanner.jpg";
import BlogImg1 from "../../Images/blogImg1.jpg";
import BlogImg2 from "../../Images/blogImg2.jpg";
import BlogImg3 from "../../Images/blogImg3.jpg";
import RecentPostImg1 from "../../Images/RecentPostImg1.jpg";
import RecentPostImg2 from "../../Images/RecentPostImg2.jpg";
import RecentPostImg3 from "../../Images/RecentPostImg3.jpg";
import RecentPostImg4 from "../../Images/RecentPostImg4.jpg";
import BlogMain from "../../Images/BlogMain.jpg";
import { Link } from "react-router-dom";

const BlogDetails = () => {
  const breadcrumbItems = [
    {
      text: "Blog Details",
    },
  ];

  const blogdata = [
    {
      image: BlogImg1,
      title: "  New York Fashion Story",
      date: " April 16, 2019 Lifestyle by Joan Clare",
      des: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.",
      des2: "“Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui.Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus.”",
    },
  ];

  const recentpost = [
    {
      image: RecentPostImg1,
      title: "  New York Fashion Story",
      date: " April 16, 2019 Lifestyle by Joan Clare",
    },
    {
      image: RecentPostImg2,
      title: "  New York Fashion Story",
      date: " April 16, 2019 Lifestyle by Joan Clare",
    },
    {
      image: RecentPostImg3,
      title: "  New York Fashion Story",
      date: " April 16, 2019 Lifestyle by Joan Clare",
    },
    {
      image: RecentPostImg4,
      title: "  New York Fashion Story",
      date: " April 16, 2019 Lifestyle by Joan Clare",
    },
  ];
  return (
    <div className="dark:bg-darkmode dark:text-white">
      <Breadcrumb items={breadcrumbItems} backgroundImage={BlogBanner} />
      <div className="container mx-auto pt-14 pb-10 px-3">
        <div className="md:flex gap-10">
          <div className="md:w-3/4">
            {blogdata.map((val, index) => (
              <div className="mb-10" key={index}>
                <h1 className="pb-5 text-3xl font-semibold">
                  {val.title}
                </h1>
                <div className="border-t-4 border-themecolor w-1/4 flex"></div>
                <p className="text-sm text-gray-400 pt-2 pb-5">{val.date}</p>
                <div className="overflow-hidden relative lg:h-[30rem] h-60 rounded-md mb-6">
                  <img
                    src={val.image}
                    alt=""
                    className="h-full w-full object-cover"
                  />
                </div>
                <p className="text-sm lg:text-base">{val.des} </p>
                <p className="text-sm font-semibold py-4 text-center lg:text-base">
                  {val.des2}{" "}
                </p>
                <p className="text-sm lg:text-base">{val.des} </p>
              </div>
            ))}
          </div>


          <div className="md:md:w-1/4">

            <div className="mx-auto sticky lg:top-32">
              <div className="mb-5">
                <div className="rounded-xl shadow-[0_0px_15px_-6px_rgba(0,0,0,0.3)] hover:translate-y-[-5px] transition ease duration-300 dark:border">
                  <h1 className="text-center text-white text-lg font-semibold uppercase py-2 bg-themecolor1 rounded-t-lg">
                    Recent Posts
                  </h1>

                  <div className="py-4">
                    {recentpost.map((val, index) => (
                      <Link to={"/blog-details"}>
                        <div className="mb-3 flex px-3">
                          <div className="overflow-hidden  relative h-20 w-40 rounded me-5">
                            <img
                              src={val.image}
                              alt=""
                              className="h-full w-full object-cover object-top"
                            />
                          </div>
                          <div className="w-5/6">
                            <h1 className="text-sm font-semibold">
                              {val.title}
                            </h1>

                            <p className="text-gray-400 text-[12px] pt-1">
                              {val.date}
                            </p>
                          </div>
                        </div>
                      </Link>

                    ))}
                  </div>
                </div>
              </div>

              <div className="mb-5">
                <div className="rounded-xl shadow-[0_0px_15px_-6px_rgba(0,0,0,0.3)] hover:translate-y-[-5px] transition ease duration-300 dark:border">
                  <h1 className="text-center text-white text-lg font-semibold uppercase py-2 bg-themecolor1 rounded-t-lg">
                  Categories
                  </h1>
                    <div className="py-5 px-3">
                        <div className="flex justify-center flex-wrap gap-2">                           
                            <Link className="bg-gray-200 border border-gray-200 text-sm py-1 rounded-sm px-3 hover:bg-gray-400 hover:text-white " to={"/blog-details"}>Models</Link>
                            <Link className="bg-gray-200 border border-gray-200 text-sm py-1 rounded-sm px-3 hover:bg-gray-400 hover:text-white " to={"/blog-details"}>Actors</Link>
                            <Link className="bg-gray-200 border border-gray-200 text-sm py-1 rounded-sm px-3 hover:bg-gray-400 hover:text-white " to={"/blog-details"}>Photographers</Link>
                            <Link className="bg-gray-200 border border-gray-200 text-sm py-1 rounded-sm px-3 hover:bg-gray-400 hover:text-white " to={"/blog-details"}>Dancers</Link>
                            <Link className="bg-gray-200 border border-gray-200 text-sm py-1 rounded-sm px-3 hover:bg-gray-400 hover:text-white " to={"/blog-details"}>Singers</Link>
                            <Link className="bg-gray-200 border border-gray-200 text-sm py-1 rounded-sm px-3 hover:bg-gray-400 hover:text-white " to={"/blog-details"}>Model Factory</Link>
                            <Link className="bg-gray-200 border border-gray-200 text-sm py-1 rounded-sm px-3 hover:bg-gray-400 hover:text-white " to={"/blog-details"}>Tips</Link>
                            <Link className="bg-gray-200 border border-gray-200 text-sm py-1 rounded-sm px-3 hover:bg-gray-400 hover:text-white " to={"/blog-details"}>Recruiters</Link>
                            <Link className="bg-gray-200 border border-gray-200 text-sm py-1 rounded-sm px-3 hover:bg-gray-400 hover:text-white " to={"/blog-details"}>Movies</Link>
                        </div>
                    </div>
                </div>
              </div>
              

              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
