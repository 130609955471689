/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import HomeBackImg from "../../Images/home-page-back.png";

import titleimage from "../../Images/title-icon.png";
import { Link, useLocation, useParams } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import NoDataFound from "../../common/NodataFound";
import ClipLoader from "react-spinners/FadeLoader";
import usePageSEO from "../usepageSEO/Index";
import { getInfluencerBySubCategoryId } from "../../API/NewInfluencerAPI";

const CatSubCategory = () => {
  const { Slug } = useParams();
  const { Slug1 } = useParams();

  const location = useLocation();
  const state = location.state || {}; // Default to empty object if no state

  const CategoryId = state.CategoryId || "No ID provided";
  console.log("Passed CategoryId:", CategoryId);

  const [InfluencerData, setInfluencerData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const getData = async () => {
    try {
      const results = await getInfluencerBySubCategoryId(CategoryId);
      setInfluencerData(results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [CategoryId]);

  usePageSEO(
    "Viral kar | Category", // Use page title for SEO if available
    "Viral kar | Category", // Use page description for SEO if available
    ["Viral kar | Category"] // No keywords provided in this example
  );

  return (
    <div className="dark:bg-darkmode dark:text-white">
      {/*-------------- male - Influencers -----------------*/}

      <div className="container mx-auto  py-10">
        <p className="page-title text-center dark:text-white capitalize pb-3">
          {Slug} {Slug1}
        </p>
        <img src={titleimage} alt="" className="mx-auto title-icon" />
      </div>
      <div className="">
        <div
          className="subscribe-bg lg:mb-0 relative bg-cover bg-center bg-no-repeat py-20 px-5 md:px-9 xl:px-0 "
          style={{ backgroundImage: `url(${HomeBackImg})` }}
        >
          {loading ? (
            <div className="flex justify-center items-center my-40">
              <ClipLoader color={"#f59231"} loading={loading} size={40} />
            </div>
          ) : (
            <div className="container mx-auto relative">
              {InfluencerData?.length === 0 ? (
                <NoDataFound />
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 container mx-auto ">
                  {InfluencerData?.map((val, index) => {
                    return (
                      <div class="item pb-[146px] group relative grid grid-cols-1 ">
                        <div class="relative overflow-hidden surface:h-[20rem] xl:h-[25rem] h-60">
                          <img
                            class="block w-full transform-style-[preserve-3d] h-full object-cover object-top rounded-t-lg"
                            src={val.Path + val.ProfileImage}
                            alt="#"
                          />
                        </div>
                        <div class="  border-l-3 border-[#95c6d8] shadow-md rounded-b-lg bottom-0 left-0 overflow-hidden perspective-[500px] min-h-[146px] absolute right-0 px-[20px]   pt-[20px] transition-all duration-500 ease-in-out bg-white dark:bg-darkmode dark:text-white z-20 group-hover:bg-[#f5f5f5] dark:group-hover:bg-darkmode group-hover:border-l-transparent group-hover:pt-[37px] group-hover:-translate-y-4 ">
                          <h3 class=" text-xl text-left font-semibold leading-[1] tracking-normal mb-[18px] transition-transform duration-500 ease-in-out transform group-hover:-translate-y-2">
                            <Link to={`/influencer/${val.Id}`}>{val.Name}</Link>
                          </h3>
                          <h3 class="text-md text-left leading-[1]   tracking-normal my-[18px] transition-transform duration-500 ease-in-out transform group-hover:-translate-y-2">
                            {val.Name}
                          </h3>

                          <div class="absolute bottom-0 right-0 top-0 w-[40px] bg-black opacity-0 transition-all duration-500 ease-in-out transform translate-y-[50px] group-hover:translate-y-0 group-hover:opacity-100 flex flex-col justify-center space-y-4 text-white text-xl text-center">
                            <div class="social-icon ">
                              <Link
                                to={val.Facebook || "https://facebook.com"}
                                target="_blank"
                                className="text-center"
                              >
                                <span>
                                  <FaFacebookF className="mx-auto" />
                                </span>
                              </Link>
                            </div>
                            <div class="social-icon">
                              <Link
                                to={val.Twitter || "https://x.com"}
                                target="_blank"
                                className="text-center"
                              >
                                <span>
                                  <FaXTwitter className="mx-auto" />
                                </span>
                              </Link>
                            </div>
                            <div class="social-icon">
                              <Link
                                to={val.Youtube || "https://youtube.com"}
                                target="_blank"
                                className="text-center"
                              >
                                <span>
                                  <FaYoutube className="mx-auto" />
                                </span>
                              </Link>
                            </div>
                            <div class="social-icon">
                              <Link
                                to={val.Instagram || "https://instagram.com"}
                                target="_blank"
                                className="text-center"
                              >
                                <span>
                                  <FaInstagram className="mx-auto" />
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CatSubCategory;
