import React, { useEffect, useState } from "react";
import HomeBackImg from "../../../Images/home-page-back.png";
import Slider from "react-slick";
import MaleInflueImg1 from "../../../Images/FM-IN-01.jpg";
import MaleInflueImg2 from "../../../Images/FM-IN-02.jpg";
import MaleInflueImg3 from "../../../Images/FM-IN-03.jpg";
import MaleInflueImg4 from "../../../Images/FM-IN-04.jpg";
import MaleInflueImg5 from "../../../Images/FM-IN-05.jpg";
import MaleInflueImg6 from "../../../Images/FM-IN-06.jpg";
import MaleInflueImg7 from "../../../Images/FM-IN-07.jpg";
import MaleInflueImg8 from "../../../Images/FM-IN-08.jpg";
import titleimage from "../../../Images/title-icon.png";
import { IoSearchOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import NoDataFound from "../../../common/NodataFound";
import ClipLoader from "react-spinners/FadeLoader";
import usePageSEO from "../../usepageSEO/Index";
import { getAllInfluencer } from "../../../API/NewInfluencerAPI";

const FemaleInfluencer = () => {
  usePageSEO(
    "Viral kar | Female Influencer", // Use page title for SEO if available
    "Viral kar | Female Influencer", // Use page description for SEO if available
    ["Viral kar | Female Influencer"] // No keywords provided in this example
  );
  const topinfluencer = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const topinfluencerdata = [
    { image: MaleInflueImg1 },
    { image: MaleInflueImg2 },
    { image: MaleInflueImg3 },
    { image: MaleInflueImg4 },
    { image: MaleInflueImg5 },
    { image: MaleInflueImg6 },
    { image: MaleInflueImg7 },
    { image: MaleInflueImg8 },
  ];

  const [InfluencerData, setInfluencerData] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const [loading, setLoading] = useState(true); // Loading state
  const getData = async () => {
    try {
      const results = await getAllInfluencer();
      const filteredResults = results.filter(
        (influencer) => influencer.Gender === "F"
      );
      setInfluencerData(filteredResults);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  // Filter influencers based on search query
  const filteredInfluencers = InfluencerData?.filter((influencer) =>
    influencer.Name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="dark:bg-darkmode dark:text-white">
      {/*-------------- Top - Influencers -----------------*/}
      <div
        className=" subscribe-bg lg:mb-0 relative px-5 md:px-9 xl:px-0 pt-10" >
        <div className="container mx-auto pb-7">
          <h1 className="text-4xl text-center pb-4">Top Influencers</h1>
          <img src={titleimage} alt="" className="mx-auto title-icon" />
        </div>
        <div className="Slide z-40">
          <Slider {...topinfluencer}>
            {topinfluencerdata.map((val, index) => (
              <div
                key={index}
                className="relative overflow-hidden xl:h-[25rem] lg:h-[20.5rem] md:h-96 h-[26rem]"
              >
                <img
                  src={val.image}
                  alt=""
                  className="h-full w-full lg:object-cover p-4 object-cover lg:object-top"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/*-------------- Femmale - Influencers -----------------*/}

      <div className="container mx-auto xl:py-20 py-10 px-2 xl:px-0 lg:px-4 ">
        <p className="page-title text-center pb-4">Female Influencers</p>
        <img src={titleimage} alt="" className="mx-auto title-icon" />

        <div className="pt-10">
          <div className="flex items-center xl:w-1/4 lg:w-1/3 md:w-1/2 w-full border border-themecolor dark:border-slate-300 rounded  ">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="border-r border-themecolor dark:border-slate-300  p-3 w-full outline-none bg-transparent"
              placeholder="Search by Names"
            />
            <h1 className="my-auto text-3xl mx-auto px-4">
              <IoSearchOutline />
            </h1>
          </div>
        </div>
      </div>

      <div className="">
        <div
          className="subscribe-bg lg:mb-0 relative bg-gray-50 lg:py-20 py-10 px-5 md:px-9 xl:px-0 "        >
          {loading ? (
            <div className="flex justify-center items-center mt-40">
              <ClipLoader color={"#d00067"} loading={loading} size={40} />
            </div>
          ) : (
            <div className="container mx-auto relative">
              {filteredInfluencers?.length === 0 ? (
                <NoDataFound />
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 container mx-auto ">
                  {filteredInfluencers?.map((val, index) => {
                    return (
                      <Link to={`/influencer/${val.Id}`}>
                        <div class="item pb-[146px] group relative grid grid-cols-1 ">
                          <div class="relative overflow-hidden surface:h-[20rem] xl:h-[25rem] h-60">
                            <img
                              class="block w-full transform-style-[preserve-3d] h-full object-cover object-top rounded-t-lg"
                              src={val.Path + val.ProfileImage}
                              alt="#"
                            />
                          </div>
                          <div class="  border-l-3 border-[#95c6d8] shadow-md rounded-b-lg bottom-0 left-0 overflow-hidden perspective-[500px] min-h-[146px] absolute right-0 px-[20px]   pt-[20px] transition-all duration-500 ease-in-out bg-white dark:bg-darkmode dark:text-white z-20 group-hover:bg-[#f5f5f5] dark:group-hover:bg-darkmode group-hover:border-l-transparent group-hover:pt-[37px] group-hover:-translate-y-4 ">
                            <h3 class=" text-xl text-left font-semibold leading-[1] tracking-normal mb-[18px] transition-transform duration-500 ease-in-out transform group-hover:-translate-y-2">
                              <Link to={`/influencer/${val.Id}`}>
                                {val.Name}
                              </Link>
                            </h3>
                            <h3 class="text-md text-left leading-[1]   tracking-normal my-[18px] transition-transform duration-500 ease-in-out transform group-hover:-translate-y-2">
                              {val.Name}
                            </h3>

                            <div class="absolute bottom-0 right-0 top-0 w-[40px] bg-black opacity-0 transition-all duration-500 ease-in-out transform translate-y-[50px] group-hover:translate-y-0 group-hover:opacity-100 flex flex-col justify-center space-y-4 text-white text-xl text-center">
                              <div class="social-icon ">
                                <Link
                                  to={val.Facebook || "https://facebook.com"}
                                  target="_blank"
                                  className="text-center"
                                >
                                  <span>
                                    <FaFacebookF className="mx-auto" />
                                  </span>
                                </Link>
                              </div>
                              <div class="social-icon">
                                <Link
                                  to={val.Twitter || "https://x.com"}
                                  target="_blank"
                                  className="text-center"
                                >
                                  <span>
                                    <FaXTwitter className="mx-auto" />
                                  </span>
                                </Link>
                              </div>
                              <div class="social-icon">
                                <Link
                                  to={val.Youtube || "https://youtube.com"}
                                  target="_blank"
                                  className="text-center"
                                >
                                  <span>
                                    <FaYoutube className="mx-auto" />
                                  </span>
                                </Link>
                              </div>
                              <div class="social-icon">
                                <Link
                                  to={val.Instagram || "https://instagram.com"}
                                  target="_blank"
                                  className="text-center"
                                >
                                  <span>
                                    <FaInstagram className="mx-auto" />
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FemaleInfluencer;
