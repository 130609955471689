// import React from "react";

// const Breadcrumb = ({ items, backgroundImage }) => {
//   const breadcrumbStyle = {
//     backgroundImage: `url(${backgroundImage})`,
//     backgroundSize: "cover",
//     padding: "140px 0px",
//     position: "relative",
//     backgroundPosition: "center",
//     zIndex: 1, // Note: Remove quotes around the value
//   };

//   const breadcrumbBeforeStyle = {
//     position: "absolute",
//     left: 0,
//     top: 0,
//     height: "100%",
//     width: "100%",
//     zIndex: -1,
//     // backgroundColor: "#353530b0",
//     content: "''",
//     opacity: 0.9,
//     backgroundSize: "cover",
//   };

//   return (
//     <nav className="breadcrumb" aria-label="breadcrumbs">
//       <div className="breadcrumb-bg" style={breadcrumbStyle}>
//         <div style={breadcrumbBeforeStyle}></div>
//         <ul>
//           {items.map((item, index) => (
//             <li key={index} className="ml-20">
//               <h1 className="text-white lg:text-5xl md:text-3xl text-2xl  font-semibold">
//                 {item.text}
//               </h1>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </nav>
//   );
// };

// export default Breadcrumb;

import React from "react";
import { GoDotFill } from "react-icons/go";
import { Link } from "react-router-dom";

const Breadcrumb = ({ items, backgroundImage }) => {
  const breadcrumbStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    position: "relative",
    backgroundPosition: "top",
    zIndex: 1,
  };

  return (
    <nav className="breadcrumb" aria-label="breadcrumbs">
      <div className="breadcrumb-bg" style={breadcrumbStyle}>
        <div className="breadcrumb-before"></div>
        <ul className="breadcrumb-list">
          {items.map((item, index) => (
            <li key={index} className="breadcrumb-item">
              <h1 className="breadcrumb-text uppercase border-b border-t">
                {item.text}
              </h1>
              <div className="flex pt-8 justify-center space-x-4 text-center text-lg text-white">
                <Link to={"/"}>
                  <h1 className=" hover:text-themecolor duration-300">Home</h1>
                </Link>
                <p className="my-auto">
                  <GoDotFill />
                </p>
                <p>{item.text}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Breadcrumb;
