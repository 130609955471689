import React, { useState } from "react";
import Breadcrumb from "../../Breadcumb/Breadcumb";
import ContactusImg from "../../Images/contact-back.jpg";
import titleimage from "../../Images/title-icon.png";
import { FaEnvelope, FaMobile, FaPaperPlane, FaPlane } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { useFormik } from "formik";

import * as Yup from "yup";
import FormLoader from "../../common/FormLoader";
import ScrollToTop from "./ScrollToTop";
import { sendContact } from "../../API/ContactUsApi";
import usePageSEO from "../usepageSEO/Index";
import { Link } from "react-router-dom";

const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Name cannot contain numbers")
    .required("Name is required"),

  Email: Yup.string().email("Invalid email").required("Email is required"),

  Phone: Yup.string()
    .matches(/^[0-9]+$/, "Invalid mobile number")
    .required("Number is required"),
  City: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "City cannot contain numbers")
    .required("City is required"),
  Message: Yup.string().required("Message is required"),
});

const Contact = () => {
  const breadcrumbItems = [
    {
      text: "Contact Us",
    },
  ];

  usePageSEO(
    "Viral kar | Contact", // Use page title for SEO if available
    "Viral kar | Contact", // Use page description for SEO if available
    ["Viral kar | Contact"] // No keywords provided in this example
  );
  const backgroundImage = ContactusImg;
  const [isFormLoading, setIsFormLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      Name: "",
      Email: "",
      Phone: "",
      City: "",
      Message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await sendContact(values);
        console.log(response);
        if (response.status === true) {
          actions.resetForm();
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });
  return (
    <div className="dark:bg-darkmode dark:text-white">
      {" "}
      {/* <Breadcrumb items={breadcrumbItems} backgroundImage={backgroundImage} /> */}
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <ScrollToTop />
      <div className="py-10 container mx-auto px-5">
        <div className="flex items-center justify-center flex-col">
          <p className="page-title text-center">Contact Us</p>
          <p className="px-10 text-center mb-4">
            We’re here to help! If you have any questions, need assistance, or
            want to provide feedback, please reach out to us.
          </p>
          <img src={titleimage} className="text-center title-icon" alt="" />
        </div>
        <div className=" md:flex mt-8 md:gap-10">
          <div className="md:w-3/5 md:mb-0 mb-5">
            <div className=" rounded-lg bg-dark-light px-8 py-10  dark:border border-white">
              <form
                onSubmit={formik.handleSubmit}
                className=""
              >
                <div className="grid grid-cols-2 gap-5">
                    <div className="">
                    <label class="text-base text-gray-400">Full Name <span class="text-red-500">*</span></label>
                      <input
                        type="text"
                        name="Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Name}
                        placeholder="Full Name"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border mt-3 border-zinc-200 rounded-md dark:bg-[#020617]"
                      />
                      {formik.touched.Name && formik.errors.Name ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Name}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <label className="text-base text-gray-400">Email Address</label>
                      <input
                        type="email"
                        name="Email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Email}
                        placeholder="Email Address"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border mt-3 border-zinc-200 mt-2 rounded-md dark:bg-[#020617]"
                      />
                      {formik.touched.Email && formik.errors.Email ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Email}
                        </div>
                      ) : null}
                    </div>
                    <div>
                    <label className="text-base text-gray-400">Phone Number</label>
                      <input
                        type="number"
                        name="Phone"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Phone}
                        placeholder="Phone Number"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 mt-2 rounded-md dark:bg-[#020617]"
                      />
                      {formik.touched.Phone && formik.errors.Phone ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Phone}
                        </div>
                      ) : null}
                    </div>
                    <div>
                    <label className="text-base text-gray-400 ">City</label>
                      <input
                        type="text"
                        name="City"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.City}
                        placeholder="Your City"
                        className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.City && formik.errors.City ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.City}
                        </div>
                      ) : null}
                    </div>
                
                </div>
                <div className="mb-3 mt-4">
                  <label className="text-base text-gray-400">Message</label>
                    <textarea
                      placeholder="Message"
                      className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 mt-3 rounded-md dark:bg-[#020617]"
                      type="text"
                      name="Message"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Message}
                    ></textarea>
                  </div>

                {formik.touched.Message && formik.errors.Message ? (
                  <div className="text-red-500 text-sm">{formik.errors.Message}</div>
                ) : null}

                <div className="mt-5">
                  <button
                    type="submit"
                    className="flex items-center justify-center bg-themebgcolor3 hover:bg-themecolor rounded-full text-sm font-semibold text-white p-4 px-5 md:px-10 mx-auto"
                  >
                    <FaPaperPlane className="mr-3" /> Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="md:w-2/5">
            <div className=" rounded-lg bg-dark-light p-4  dark:border border-white">
              <div className="grid md:grid-rows-3 col-span-12">
                <div className="">
                  <div className="mx-auto text-center py-6">
                    <FaEnvelope className="bg-themecolor mx-auto p-3 text-white text-5xl mb-3 rounded-md" />
                    <p className="mb-2"> <a href="mailto:connect@viralkar.com">connect@viralkar.com</a> </p>
                  </div>
                </div>
                {/* <div className="">
                  <div className="mx-auto text-center p-7">
                    <FaMobile className="bg-themecolor mx-auto p-3 text-white text-5xl mb-3 rounded-md" />
                    <p className="mb-2">+91 1234567890</p>
                  </div>
                </div> */}
                <div className="">
                  <div className="mx-auto text-center p-6">
                    <FaLocationDot className="bg-themecolor mx-auto p-3 text-white text-5xl mb-3 rounded-md" />
                    <p className="mb-2">Ahmedabad</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>




      </div>
      <section class="map-section">
        <div class="container-fluid map-box">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235013.74843640297!2d72.41493349036689!3d23.02047409729473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e848aba5bd449%3A0x4fcedd11614f6516!2sAhmedabad%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1725343140897!5m2!1sen!2sin"
            width="100%"
            height="500px"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </div>
  );
};

export default Contact;
